@use '../../../../styles/variables' as *;

.messages {
  padding-top: 40px;
  display: flex;
  width: 520px;
  height: 60px;
  flex-direction: column;
  justify-content: center;  
  flex-shrink: 0;
  font-family: $font-family-base;
  font-size: 16px;
  color: $eerie-black;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  p {
    margin-bottom: 5px;
  }
}

.deleteButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.deleteButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}


.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
}

.icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  color: $orange;
}