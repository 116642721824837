@use '../../../../styles/variables' as *;

.formUsers {
  display: grid;
  gap: 20px;
  width: fit-content;

  .imageFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    vertical-align: middle;
  }
  .errorMsg {
    color: $red;
    display: inline-block;
    margin: 5px 0 0;
    width: 100%;
    font-size: 12px;
  }
  .editLabel {
    color: $process-cyan;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:hover {
      background: rgba($process-cyan, 0.12);
    }
  }

  .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
  }

  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .label {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .requiredMark {
    color: $red;
  }

  .inputField {
    flex: 1;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $anti-flash-white;
    background-color: $anti-flash-white;

    &::placeholder {
      color: $dim-gray;
    }

    &:focus {
      border-color: $process-cyan;
      background-color: $seasalt;
      outline: none;
    }
  }
}

.selectUserType,
.selectHome {
  width: 200px;
}

.saveButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  grid-column: 1 / span 2;
  justify-self: end;
}

.saveButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  grid-column: 1 / span 2;
  justify-self: start;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
  grid-column: 1 / span 2;
}

.dateClass {
  width: 250px;
}