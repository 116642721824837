@use '../../../../styles/variables' as *;

.confirmButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }

  &.disabled {
    background: $dim-gray;
    border-color: $dim-gray;
    color: $white;
    cursor: not-allowed;

    &:hover {
      background: $dark-gray;
      border-color: $dark-gray;
    }
  }
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: auto;
    max-width: 300px;
    background: $white;
    color: $eerie-black;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $alice-blue;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 3;
    bottom: 125%;
    left: 55%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}