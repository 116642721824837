@use '../../../styles/variables' as *;

.profileView {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.navigation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    padding: 15px 5px;
    border-bottom: 1px solid $anti-flash-white;
    height: 155px;
}

.menuRow {
    height: 60px;
}

.iconWithLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &.selected {
        color: $process-cyan;
    }

    &:hover {
        cursor: pointer;
        color: $process-cyan;
    }
}

.componentDisplay {
    flex: 1;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    max-height: calc(100dvh - 285px); //100% - header - nav - navprofile
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }
}
