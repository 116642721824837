@use '../../../../styles/variables' as *;

.editModalContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
}

.inputGroup label {
    margin-bottom: 5px;
    font-weight: bold;
}

.errorText {
    color: $red;
    font-size: 14px;
}

.saveButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
  }
  
  .saveButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }
  
  .cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    grid-column: 1 / span 2;
    justify-self: start;
  }
  
  .cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
  }
  
  .modalTitle {
    color: $black;
    font-family: $font-family-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 15px;
    grid-column: 1 / span 2;
  }
