@use '../../../styles/variables' as *;

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.role {
    margin-top: 10px;
    color: $process-cyan;
    text-align: center;
    line-height: 140%;
    font-family: $font-family-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.name {
    color: $black;
    text-align: center;
    max-width: 100%;
    line-height: 140%;
    font-family: $font-family-base;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
}

.email {
    font: inherit;
    color: $dim-gray;
}

.videocallButton {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    padding: 8px 0px;
    background-color: $emerald;
    border: 1px solid $emerald;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $b-light;
    text-align: center;
    gap: 15px;
    cursor: pointer;
}

.videocallButton:hover {
    background-color: $kelly-green;
}

.videocallText {
    font-family: $font-family-base;
}

.miscSection {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: $gray;
    font-weight: 400;
    white-space: nowrap;
    line-height: 140%;
}

.niu,
.homeName {
    font-family: $font-family-base;
}

.modalTitle {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 23px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 140%;
}

.modalAcceptButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
}

.tags {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.confirmButton {
    color: $white;
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
}

.confirmButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
}


.cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
}

.cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
}