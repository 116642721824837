@use '../../../../../styles/variables' as *;

.modalTitle {
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 140%;
}
.modalAcceptButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  grid-column: 1 / span 2;
  justify-self: end;
}

.modalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  margin-bottom: 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}