@use '../../../../styles/variables' as *;

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    border: 1px solid $b-light;
    border-radius: 10px;
    background-color: $white;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: $blue-light;
    }
}

.dropdownContainer {
    position: absolute;
    top: 15px;
    right: 15px;
}

.dropIcon {
    cursor: pointer;
    color: $dim-gray;
    transform: rotate(90deg);
}

.dropdownMenu {
    position: absolute;
    top: 30px;
    right: 0;
    width: 200px;
    padding: 10px;
    background-color: $white;
    border: 1px solid $alice-blue;
    border-radius: 5px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 6px 10px;
      font-size: 14px;
      font-family: $font-family-base;
      font-weight: 400;
      background: none;
      border: none;
      text-align: left;
      cursor: pointer;
      color: $eerie-black;
      transition: background 0.3s ease;

      .iconButton {
        margin-right: 8px;
      }

      &:hover {
        background: rgba($process-cyan, 0.12);

        .iconButton {
          color: $process-cyan;
        }
      }
    }
  }

.iconButton {
    margin-right: 8px;
}

.content {
    display: flex;
    margin-top: 5px;
    gap: 10px;
}

.imageCollage {
    display: grid;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}

.collageImage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $white;
    font-weight: bold;
}

.imageCollage.members-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.imageCollage.members-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.imageCollage.members-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "a b"
        "c c";
}

.imageCollage.members-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.imageCollage.members-1 .collageImage:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.imageCollage.members-2 .collageImage:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.imageCollage.members-2 .collageImage:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.imageCollage.members-3 .collageImage:nth-child(1) {
    grid-area: a;
}

.imageCollage.members-3 .collageImage:nth-child(2) {
    grid-area: b;
}

.imageCollage.members-3 .collageImage:nth-child(3) {
    grid-area: c;
}

.groupInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.groupName {
    font-size: 18px;
    font-weight: 600;
    color: $black;
}

.memberCount {
    font-size: 14px;
    color: $dim-gray;
}

.memberPreview {
    font-size: 14px;
    color: $dim-gray;
    margin-top: 5px;
}

.status {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 14px;
    color: $dim-gray;
    display: flex;
    align-items: center;
}

.liveIndicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    margin-right: 8px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
