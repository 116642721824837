@use '../../../styles/variables' as *;
.rootContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.navigation {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: $eerie-black;
  font-weight: 400;
  text-align: center;
  line-height: 140%;
  padding: 20px 0;
  gap: 30px;
  background: $seasalt-background;
  height: 70px;

  &.horizontal {
      flex-direction: row;
      border-bottom: 1px solid rgba($black, 0.144);
  }

  &.vertical {
      flex-direction: column;
      width: 90px;
      background-color: $seasalt-background;
  }
}

.iconWithLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  font-family: $font-family-base;
  font-size: 18px;

  &.selected {
      color: $process-cyan;
  }

  &:hover {
      cursor: pointer;
      color: $process-cyan;
  }
}

.menuIcon {
  height: 25px;
  width: 27px;
}

.label {
  white-space: nowrap;
}

.componentDisplay {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100dvh - 130px);
}