@use '../../../../styles/variables' as *;

.tagInputContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; 
    align-items: flex-start;
    border-radius: 5px;
    width: 90%;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; 
}

.tagInput {
    border: none;
    outline: none;
    height: 35px;
    padding: 10px;
    background-color: $anti-flash-white;
    border-radius: 5px;
    width: 200px;
    box-sizing: border-box;
}
