@use '../../../styles/variables' as *;

.callDetails {
    font-family: $font-family-base;
    margin: auto 0;
}

.stateCall{
    min-width: 120px;
}

.boldTime {
    font-weight: 700;
}

.green {
    color: $kelly-green;
}

.red {
    color: $red;
}

.black {
    color: $black;
}