@use '../../../styles/variables' as *;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  padding: 25px 33px;
  max-width: 1500px;
  width: auto;
  border-radius: 5px;
  border: 0.75px solid $anti-flash-white;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.modalTitle {
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  flex-shrink: 0;
  padding: 51px 0px 0px 0px;
}

.modalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  margin-bottom: 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: $black;

  &:hover {
    color: $process-cyan;
  }

  &:focus {
    outline: none;
  }
}

.avatar {
  display: flex;
  justify-content: center;
  margin: 10px 0px 15px 0px;
}