@use '../../../../styles/variables' as *;

.formContent {
  display: grid;
  gap: 20px;
  width: 950px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  vertical-align: middle;
}
.switchGroup {
  display: flex;
  align-items: center;
  row-gap: auto;
  display: flex;
  width: 120px;
  margin-left: auto;
}

.switchLabel {
  flex: 1 0 0;
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: $white;
  transition: .4s;
}

input:checked + .slider {
  background-color: $green-1;
}

input:focus + .slider {
  box-shadow: 0 0 1px $green-1;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.saveButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.saveButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
}

.requiredMark {
  color: $red;
}

.inputField {
  flex: 1;
}

.inputCp {
  width: 160px;
}
