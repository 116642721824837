@use '../../../styles/variables' as *;

.saveButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.saveButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
}

.dropBox {
  display: flex;
  width: 450px;
  height: 440px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  flex-shrink: 0;
  justify-content: center;
  border: 2px dashed $gray;
  cursor: pointer;

  &:hover,
  &:active {
    border-color: $process-cyan;
    .icon {
      color: $process-cyan;
    }
  }
}

.icon {
  color: $gray;
  font-size: 67px;
}

.fileName {
  margin-top: 10px;
  font-size: 14px;
  color: $gray;
}
