// modalCreateGroup.module.scss
@use '../../../../../styles/variables' as *;

.groupCreateModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup label {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 600;
  color: $eerie-black;
}

.inputField {
  padding: 10px;
  border: 1px solid $gray;
  border-radius: 5px;
  color: $eerie-black;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.confirmButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.confirmButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.requiredMark {
  color: red;
}

.buttonGroup {
  display: flex;
  gap: 25px;
}

.emailInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addButton {
  color: $white;
  display: flex;
  padding: 0px 20px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.addButton:hover {
  background-color: $blue;
}

.emailDropdown {
  margin-top: 10px;
}

.errorText {
  color: $red;
  font-size: 12px;
}
