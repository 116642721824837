@use '../../../../styles/variables' as *;

.messages {
  padding-top: 40px;
  display: flex;
  width: 520px;
  height: 60px;
  flex-direction: column;
  justify-content: center;  
  flex-shrink: 0;
  font-family: $font-family-base;
  font-size: 16px;
  color: $eerie-black;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  p {
    margin-bottom: 5px;
  }
}

.saveButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.saveButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
}

.tableContainer {
  overflow-y: auto;
  height: 300px;
  max-height: 300px;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: users-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }
  
  .usersTable {
    width: 100%;
    border-collapse: collapse;

    .textEmail {
      color: $process-cyan !important;
      text-decoration: none;
    }

    td {
      padding: 10px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: $eerie-black;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      input[type="checkbox"] {
        cursor: pointer;
      }

      .contactInfo {
        display: flex;
        flex-direction: column;

        .usersTitle {
          color: $dim-gray;
          font-size: 12px;
          font-weight: 400;
          line-height: 140%;
        }
  
        .usersDescription {
          color: $eerie-black;
          font-size: 14px;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }
  }
}

.checkbox {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  accent-color: $process-cyan;
  color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.75px solid $gray;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  &:checked {
      background-color: $process-cyan;
      border-color: $process-cyan;

      &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 10px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -50%) rotate(45deg);
      }
  }
}

.errorMessage {
  color: $red;
  margin-top: 10px;
  text-align: center;
}

.tableTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  p {
    color: $eerie-black;
    text-align: center;
    font-family: $font-family-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 15px;
  }
}