@use '../../../styles/variables' as *;

.notificationButton {
  display: flex;
  align-items: center;
  color: #000;

  &:hover {
    cursor: pointer;
  }
}

.notyIcon {
  position: relative;
  display: inline;
}

.notyNum {
  position: absolute;
  right: 0;
  background-color: $carmine;
  font-size: 7px;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;
  margin-top: 4px;
}

.regularBellIcon {
  width: 1.5em;
  height: 1.9em;

  &.hover, .showNotification {
    color: $process-cyan;
    background-color: $white;
  }
}

.notificationWrapper {
  position: relative;
  color: $process-cyan;
}

.notificationContainer {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
}

.notificationCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  margin-top: 17px;
  padding: 26px 0px 0px;
  background-color: $white;
  border: 1px solid rgba(244, 244, 244, 1);
  border-radius: 5px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
}
.emptyNotifications{
  padding: 20px 0px 0px 0px;
  margin-top: 5px;
  border-top: 1px solid rgba(244, 244, 244, 1);
}
.notificationsContent {
  max-height: 480px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }
}

.notificationIcon {
  width: 50px;
  align-self: center;
}

.notificationMessage, .emptyNotificationMessage {
  margin-top: 8px;
  font-family: $font-family-base;
}

.notificationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;

  h2 {
    margin: 0;
    font-size: 16px;
    color: #1b1b1b;
    font-weight: 600;
    line-height: 1.4;
    font-family: $font-family-base;
  }

  .optionsIcon {
    position: relative;

    .dropIcon {
      cursor: pointer;
    }

    .dropdownMenu {
      position: absolute;
      top: 30px;
      right: 0;
      width: 240px;
      padding: 10px;
      background-color: $white;
      border: 1px solid $alice-blue;
      border-radius: 5px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 6px 10px;
        font-size: 14px;
        font-family: $font-family-base;
        font-weight: 400;
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;
        color: $eerie-black;
        transition: background 0.3s ease;

        .iconButton {
          margin-right: 8px;
        }

        &:hover {
          background: rgba($process-cyan, 0.12);

          .iconButton {
            color: $process-cyan;
          }
        }
      }
    }
  }
}

.notificationsTabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 15px 0;
  font-family: $font-family-base;

  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    padding-right: 5px;
    cursor: pointer;
    font-family: $font-family-base;


    &.activeTab {
      font-weight: 600;

      color: #1b1b1b;

      .tabUnderline {
        background-color: $process-cyan;
      }

      .tabBadge {
        background-color: $process-cyan;
      }
    }

    &.inactiveTab {
      margin-left: 5px;
      color: $gray;

      .tabLabel {
        color: $gray;
      }
    }
  }

  .tabUnderline {
    height: 2px;
    margin-top: 3px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.147);
    border-radius: 3.5px;
  }

  .tabBadge {
    background-color: $process-cyan;
    color: $white;
    border-radius: 2px;
    width: 19px;
    height: 20px;
    padding: 1px;
    font-size: 10px;
    font-family: $font-family-base;
    text-align: center;
    margin-bottom: 5px;
  }

  .tabLabel{
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
  }
}

.notificationsList {
  width: 100%;
}

.newNotificationButton {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  font-size: 14px;
  font-family: $font-family-base;
  font-weight: 400;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  color: $eerie-black;
  transition: background 0.3s ease;

  .iconButton {
    margin-right: 8px;
  }

  &:hover {
    background: rgba($process-cyan, 0.12);

    .iconButton {
      color: $process-cyan;
    }
  }
}
