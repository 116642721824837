@use '../../../styles/variables' as *;

.search {
    border-radius: 5px;
    border: 1px solid $gray;
    background-color: $white;
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: $eerie-black;
    font-weight: 400;
    white-space: nowrap;
    border-radius: 10px;
    line-height: 20px;
    padding: 0 10px;
    width: 100%;
    max-width: 430px;
}

.search:focus-within {
    border: 1px solid $process-cyan;
}

.search:focus-within .search-icon {
    color: $process-cyan;
}

.search:focus-within .clear-search-icon {
    visibility: visible;
}

.searchInputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.searchIcon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    color: $dim-gray;
}

.clearSearchIcon {
    visibility: hidden;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.clearSearchIcon:hover {
    color: $red;
}


.searchInput {
    border: none;
    font-family: $font-family-base;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    padding: 10px 0;
    flex: 1;
    width: 100%;
}

.searchInput:focus {
    border: none;
    outline: none;
}


.searchInput::placeholder {
    color: inherit;
    opacity: 1;
}

.selects {
    margin: 0;
    display: flex;
    width: 100%;
    max-width: 430px;
    justify-content: space-between;
}

.select {
    width: 195px;
}
