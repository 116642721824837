//Variables

// Colors
$white: #FFF;
$b-light: #F4F4F4;
$black: #000000;
$gray: #C4C4C4;
$dark-gray: #6b6b6b;
$seasalt-background: #FAFAFA;
$seasalt: #F7F7F7;
$orange: #FFB800;
$eerie-black: #1B1B1B;
$anti-flash-white: #EEE;
$dim-gray: #747578;
$process-cyan: #00B4E6;
$green-1: #51B44E;
$gunmental: #002830;
$prusian-blue: #022a45;
$blue: #0379CA;
$blue-light: #E6F4FF;
$alice-blue:#EDF3F8;
$emerald: #25CA66;
$kelly-green: #00AB00;
$red: #C40024;
$carmine: #9A001C;
$blue-light80: #e6f4ff80;


// Fonts
$font-family-base: 'Open Sans', sans-serif;