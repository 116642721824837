@use '../../../styles/variables' as *;

.container {
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid $b-light;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    box-sizing: border-box;
}



.container:hover {
    cursor: pointer;
    background-color: $blue-light;
}

.statusSection {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.statusBadgeContainer {
    display: flex;
    gap: 5px;
    padding-right: 18px;
    align-items: center;
}

.stateText {
    font-family: $font-family-base;
    font-size: 12px;
    color: $kelly-green;
    font-weight: 400;
    text-align: right;
    white-space: nowrap;
    line-height: 140%;
}

.contactSection {
    display: flex;
    margin-top: 5px;
    gap: 8px;
}

.contactInfo {
    display: flex;
    gap: 10px;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contactHeader {
    display: flex;
    flex-direction: row;
}

.nameUserType {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
}

.userType {
    font-size: 11px;
    color: $black;
    font-family: $font-family-base;
}

.name {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    font-family: $font-family-base;
}

.dateCall {
    font-size: 12px;
    color: $dim-gray;
    font-weight: 400;
    line-height: 140%;
    font-family: $font-family-base;
    text-align: right;
}

.calendarIcon {
    width: 18px;
    height: auto;
}

.contactFooter {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    gap: 5px;
}

.miscSection {
    display: flex;
    justify-content: left;
    margin-top: 5px;
    gap: 5px;
    padding-bottom: 3px;
    padding-left: 90px;
    font-size: 12px;
    color: $dim-gray;
    font-weight: 400;
    white-space: nowrap;
    line-height: 140%;
}

.niu,
.homeName {
    font-family: $font-family-base;
}

.infoIcon {
    width: 3px;
    height: auto;
    fill: $dim-gray;
}

.green {
    color: $kelly-green;
}

.grey {
    color: $dim-gray;
}

.red {
    color: $red;
}
