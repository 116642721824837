@use '../../../../styles/variables' as *;

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
    cursor: pointer;
}

.moreTagsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.3s;

    .icon {
        color: $gray;
    }

    &.active .icon {
        color: $process-cyan;
    }
}

.tooltip {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 8px;
    position: absolute !important;
    top: 100%;
    left: 0;
    background-color: $white;
    border: 1px solid $alice-blue;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
    z-index: 3;
}