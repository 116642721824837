@use '../../../styles/variables' as *;

.header {
    display: flex;
    align-items: center;
    background-color: $process-cyan;
    padding: 5px 20px 0px;
    height: 7.5vh;
}

.title {
    color: $eerie-black;
    text-align: center;
    font-family: $font-family-base;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}

.profileList {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.profile {
    cursor: pointer;
    text-align: center;
    width: 120px;

    @media (min-width: 768px) {
        width: 150px;
    }

    @media (min-width: 1024px) {
        width: 180px;
    }
}

.profileImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 50%;
    transition: border 0.1s ease-in-out;

    &:hover {
        border: 3px solid $process-cyan;
    }

    @media (min-width: 768px) {
        width: 130px;
        height: 130px;
    }

    @media (min-width: 1024px) {
        width: 160px;
        height: 160px;
    }
}

.profile p {
    font-size: 16px;
    color: $eerie-black;

    @media (min-width: 768px) {
        font-size: 18px;
    }

    @media (min-width: 1024px) {
        font-size: 20px;
    }
}
