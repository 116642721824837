@use '../../../styles/variables' as *;

.createButton {
    font-size: 14px;
    color: $white;
    border-radius: 5px;
    background-color: $process-cyan;
    border: none;
    cursor: pointer;
    height: 35px;
    padding: 0 10px;
}