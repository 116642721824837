@use '../../../../styles/variables' as *;

.notificationCreateModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 600px;
}

.description {
  text-align: center;
  font-family: $font-family-base;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: $eerie-black;
  flex-wrap: wrap;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup > label {
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $eerie-black;

}

.inputGroup > input {
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 10px;
  border: 1px solid $gray;
  border-radius: 5px;
  color: $eerie-black;

}

.modalTitle {
  color: $black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
}

.confirmButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.confirmButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}


.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

.messageField {
  height: 140px !important;
}

.requiredMark {
  color: red;
}

.buttonGroup {
  display: flex;
  gap: 25px;
}

.error {
  color: red;
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.label {
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $eerie-black;
}

.checkbox {
  margin-right: 5px;
  width: 14px;
  min-width: 14px;
  height: 14px;
  accent-color: $process-cyan;
  color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.75px solid $gray;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  &:checked {
      background-color: $process-cyan;
      border-color: $process-cyan;

      &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 10px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -50%) rotate(45deg);
      }
  }
}