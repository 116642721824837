@use '../../../../styles/variables' as *;

.helpSection {
    width: 100%;
    margin: 0 auto;
}

.title {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tableRow {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $anti-flash-white;
    width: 100%;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }
}

.tableRowContent {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid $anti-flash-white;
    width: 100%;
}

.tableCell {
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;
}

.tableCell:nth-child(2) {
    justify-content: flex-end;
}

.tableCellContent {
    padding: 5px;
}

.label {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.icon {
    color: $eerie-black;
    font-size: 18px;
    transition: transform 0.3s ease;

    &.open {
        transform: rotate(90deg);
    }
}

.tableCellContent {
    color: $dim-gray;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
    padding: 10px 0;
}

.closeButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
}

.showButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    justify-self: end;
    gap: 5px;
}

.content{
    max-height: 70vh;
    overflow-y: auto;
}