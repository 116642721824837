@use '../../../../styles/variables' as *;

.profileView {
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

.title {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    width: 100%;
}

.label {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.image {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 50%;
}

.editLabel {
    color: $process-cyan;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:hover {
        background: rgba($process-cyan, 0.12);
    }
}

.imageFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.value {
    color: $dim-gray;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.valueEmail {
    color: $process-cyan;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tableRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $anti-flash-white;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }
}
.tableCell2 {
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;

}
.tableCell3 {
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;

}

.tableCell2:nth-child(2), .tableCell3:nth-child(3) {
    justify-content: flex-end;
}

.buttonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
}

.select {
   width: 220px;
}


.saveButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
  }
  
  .saveButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }
  
  .cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    grid-column: 1 / span 2;
    justify-self: start;
  }
  
  .cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
  }

  .buttonRow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
  }