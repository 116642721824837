.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .modify-password-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 70px 80px;
    background-color: #fff;
  
    @media (max-width: 991px) {
      padding: 20px 20px;
    }
  }

  .modify-password-form {
    display: flex;
    flex-direction: column;
  }

  .modify-password-form .input {
    width: 330px;
    max-width: 100%;
    margin-top: 12px;
    padding: 14px 16px;
    color: var(--Dim-gray, #747578);
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    background-color: var(--Anti-flash-white, #eee);
    border-radius: 10px;
    border: none;
  }
  
  .modify-password-button {
    width: 330px;
    max-width: 100%;
    margin-top: 12px;
    padding: 10px 60px;
    color: #fff;
    font: 500 14px Poppins, sans-serif;
    text-align: center;
    background-color: var(--Process-Cyan, #00b4e6);
    border-radius: 10px;
    border: none;
  }

  .modify-password-button:hover {
    cursor: pointer;
    background-color: #0078a8;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-issues {
    color: var(--Eerie-Black, #1b1b1b);
    text-align: center;
    margin-top: 39px;
    font: 400 12px/140% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  }

  @media (max-width: 991px) {
    .login-issues {
      max-width: 100%;
    }
  }

  .help-link {
    width: 330px;
    max-width: 100%;
    padding: 4px 60px;
    color: var(--Process-Cyan, #00b4e6);
    text-align: center;
    font: 500 12px/140% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  }

  @media (max-width: 991px) {
    .help-link {
      padding: 0 20px;
    }
  }

  .password-updated-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 80px;
    background-color: #fff;
    color: var(--Text, #1b1b1b);
    font-size: 14px;
    line-height: 140%;
    text-align: center;
  }

  @media (max-width: 991px) {
    .password-updated-container {
      padding: 0 20px;
    }
  }

  .password-updated-container .success-icon {
    width: 50px;
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
  }

  .password-updated-container .success-title {
    margin-top: 25px;
    font: 600 25px Open Sans, sans-serif;
  }

  .password-updated-container .label {
    color: #1b1b1b;
    font: 16px Open Sans, sans-serif;
    margin-bottom: 12px;
  }
