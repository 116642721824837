@use '../../../../styles/variables' as *;

.dashboardContainer {
  display: flex;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}

.menuContainer {
  box-shadow: 0px 6px 6px 0px rgba($black, 0.1);
  background: $seasalt-background;

  &.horizontal {
    flex: 0 0 450px;
    max-width: 450px;
    width: 100%;
  }

  &.vertical {
    width: 90px;
    background: $seasalt-background;
  }
}

.mainContainer {
  flex: 1;
  height: 100%;
}


.modalTitle {
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 140%;
}
.modalAcceptButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  grid-column: 1 / span 2;
  justify-self: end;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}