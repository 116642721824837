@use '../../../styles/variables' as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--Process-Cyan, #00b4e6);
  gap: 16px;
  height: 60px;
}

@media (max-width: 991px) {
  .header {
    flex-wrap: wrap;
  }
}

.logo {
  width: 143px;
  max-width: 100%;
  margin: auto 0;
  aspect-ratio: 3.7;
  object-fit: contain;
  object-position: center;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  vertical-align: middle;
  padding: 0 0 10px 0px;
}

@media (max-width: 991px) {
  .nav {
    flex-wrap: wrap;
    max-width: 100%;
  }
}

.icon {
  width: 30px;
  margin: auto 0;
  aspect-ratio: 1.08;
  object-fit: contain;
  object-position: center;
}

.navLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  border-radius: 2px;
  position: relative;
}

.navLink {
  cursor: pointer;
}

.navLinkIcon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid $white;
}

.avatarContainer {
  position: relative;
  display: inline-block;
}

.statusIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
}

.notificationIcon {
  position: relative;
}

.notificationDot {
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.calendarIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: start;
}

.dropdownMenu {
  display: flex;
  width: 220px;
  padding: 20px 15px;
  position: absolute;
  top: 65px;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  z-index: 3;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
}

.dropdownButton {
  display: block;
  width: 100%;
  padding: 6px 10px;
  background: none;
  border-radius: 5px;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: rgba(0, 180, 230, 0.12);
  }
}

.iconWithLabel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 1px;
  gap: 15px;
  font-family: $font-family-base;

  &.selected {
    color: $process-cyan;
  }

  &:hover {
    cursor: pointer;
    color: $process-cyan;
  }
}

.menuIcon {
  height: 20px;
  width: 20px;
}

.label {
  white-space: nowrap;
}

.section1 {
  padding: 10px 0px;
  border-bottom: 1px solid $anti-flash-white;
}

.section2 {
  padding: 10px 0px;
}

.statusRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 10px;
  cursor: pointer;
  gap: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  &:hover {
    background: rgba(0, 180, 230, 0.12);
  }
}

.arrowIcon {
  transform: rotate(90deg);
}

.statusDropdown {
  display: flex;
  width: 180px;
  padding: 20px 15px;
  position: absolute;
  top: 60px;
  right: 15;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  z-index: 4;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

}

.statusOption {
  padding: 6px 10px;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  &:hover {
    background: rgba(0, 180, 230, 0.12);
  }
}

.online {
  color: $kelly-green;
}

.busy {
  color: $red;
}

.offline {
  color: $gray;
}