@use '../../../styles/variables' as *;

.width {
  width: 500px !important;
}

.container {
  padding: 25px 20px;
  font-family: $font-family-base;

  h1 {
    margin-bottom: 20px;
    color: $eerie-black;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .searchAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 35px;

    .searchContainer {
      display: flex;
      align-items: center;
      width: 40%;
      border: 0.5px solid $gray;
      border-radius: 4px;
      padding: 0 10px;
      border-radius: 5px;
      background: $seasalt;
      
      
      &:focus-within {
        transition: 0.5s;
        border-color: $process-cyan;

        .searchIcon {
          transition: 0.5s;
          color: $process-cyan;
        }
      }
    }

    .searchIcon {
      margin-right: 10px;
    }

    .searchBar {
      flex: 1;
      padding: 10px;
      font-size: 14px;
      border: none;
      outline: none;
      background: $seasalt;
      height: 35px;
    }

    .addUsersButton {
      font-size: 14px;
      color: $white;
      border-radius: 5px;
      background-color: $process-cyan;
      border: none;
      cursor: pointer;
      height: 35px;
      padding: 0 10px;
    }

    .addUsersButton:hover {
      transition: 0.5s;
      background-color: $blue;
    }
  }

  .scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 7px;
    background-color: $process-cyan;
    color: $white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
      background-color: $blue;
    }

    &:focus {
      outline: none;
    }
  }
}
