/*!
 * TOAST UI Calendar 2nd Edition
 * @version 2.1.3 | Tue Aug 16 2022
 * @author NHN Cloud FE Development Lab <dl_javascript@nhn.com>
 * @license MIT
 */
@use '../../../../styles/variables' as *;
:root {
  font-family: $font-family-base;
}

::-webkit-scrollbar {
    width: 0.1px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
}
 .toastui-calendar-holiday {
    color: red;
    font-size: 15px;
  }
  
  .toastui-calendar-layout {
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    border-left: 0.5px solid $anti-flash-white;
    border-right: 0.5px solid $anti-flash-white;
    border-bottom: 0.5px solid $anti-flash-white;
    &.toastui-calendar-month {
      border-top: 0.5px solid $anti-flash-white;
      border-left: 0.5px solid $anti-flash-white;
      border-right: 0.5px solid $anti-flash-white;
      border-bottom: 0.5px solid $anti-flash-white;
    }


  }
  
  .toastui-calendar-layout * {
    box-sizing: border-box;
  }
  
  .toastui-calendar-layout.toastui-calendar-dragging--move-event * {
    cursor: move;
  }
  
  .toastui-calendar-layout.toastui-calendar-dragging--resize-horizontal-event * {
    cursor: col-resize;
  }
  
  .toastui-calendar-layout.toastui-calendar-dragging--resize-vertical-event * {
    cursor: row-resize;
  }
  
  .toastui-calendar-layout .toastui-calendar-panel-resizer {
    user-select: none;
  }
  
  .toastui-calendar-layout .toastui-calendar-panel-resizer:hover {
    border-color: $anti-flash-white;
  }
  
  .toastui-calendar-layout .toastui-calendar-panel-resizer-guide {
    position: absolute;
  }
  
  .toastui-calendar-layout.toastui-calendar-horizontal .toastui-calendar-panel,
  .toastui-calendar-layout.toastui-calendar-horizontal .toastui-calendar-panel-resizer {
    display: inline-block;
    vertical-align: middle;
  }
  
  .toastui-calendar-icon {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
  }
  .toastui-calendar-icon.toastui-calendar-ic-title {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAO5JREFUKBVjYCATMOLSFxkZqfHnz5+1QHktNDVbV69e7cOCJgjmQjXtB3IksMh7g8SY0CXQNTEyMlYD1fBCabhyFI3omkCq/v//PwnotC8gGq4LyIBrxKYJpBBoU15oaCgPiEbWCPYjUEIFGBBY/QS0qRWooRVIg/UBDXgMYoBtBHJSgWxsAQFWjET8BBqQBuLDNM4Can6GpAAb8ydQMADo3x0gSbDGlStX3gVqdMSjGUUTSCNKAggPD1cDOmU/EEuBJKEAQxNIHEUjSABNM1ZNIHXMIAIZXL169a2+vv5moK18QKeXAv20B1meYjYAr7xrljpOzc0AAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-location {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAR1JREFUKBWdUTtPg1AUBiT8CydHJtv/0MTJRWAgcTK1bq0/pO3k4E4IYLo2Me46tS4wumjSpV07kAb6HXLPzaGPRZLL+c73uE/D+OdnHuaCIOhVVTUEf620pWVZ0yRJ3qW3FfR9f1zX9UgaGJumOUnT9Fn3DDzPuwPOuD9TvSzL3kizhOFJ4LnjOJc0wM0FP2Asgx0mEehHUfRHgzDzqF3GOogzbJg8V6XHFqYv4Cvqy7J8DcOwWYmw8Hwy1kHMRjcaKuEGgV82caWbZay3indagJyxcKLOlKeRdJA627YfUVaN0v6tlKbZVjCO4zW2cw91px3AxJEmOONCNoTzPP9xXZfOd6u0Bzz60RGOgmQuiuIb4S3gB0IvaoJW2QMDs1bBoH1CAQAAAABJRU5ErkJggg==) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-date {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAGpJREFUKBVjYKAGCA0N/Q/C6GZhE2cEKQoLC9v6//9/L3QN2PiMjIzbVq1a5c0EkiRWE7JasEZsJhMSI1sjC7LJq1evBvsZWQyZjRxwZNs4hDSiBA6y55EDBRsb7EdQasAmiUNsKw5x4oQBkUAeDPJ53KsAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-state {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIxJREFUKBVjYCATMKLrCw8P9/z3798soLgMVO4JExNT2sqVK7cjq2VC5oDYME2MjIyNIAwUkoGKoShlQeFBOGCbVq1a1QDihoaG1gMpmO0gITAAOzUsLGzr////vWCC+GigK7YBDfUGO5VYTSADYWox/IjPNmS5UY3IoYHGBgcOKG7QxPFxt+KTJCgHAGcZJbGLRuJ2AAAAAElFTkSuQmCC) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-private {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKpJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDqYTRSNQUBIkgewkJDZYDqYR7sewsLCtQFO9YBLYaGBAbVu1apU3SA5uIyFNIMXIauAaQRKkgBGlERTURATOVpgaABRQQOK46wEAAAAAAElFTkSuQmCC) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-public {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKdJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDq4TmREaGvofhJHFcLHhfgwLC9sKNNULl0KQODCgtq1atcobxIY7lZAmkGJkNXCNIAlSwIjSCApqIgJnK0wNALoOPwSpOcq0AAAAAElFTkSuQmCC) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-close {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAJRJREFUKBXFkNERhCAMREUbuEKohzq0Eq2DDq6Da4B60KezDORkxj+ZwchmX0IYhtdWCGFl9y5g82NtzDnPdzAaudo76ZBS+nrvPxiInMkJcs5tMcZFfqcfxdqIRiELof+BiIJPg+mExmpmvKRn3zKj7OrG9Y79szPL14A1xEP0Hgy4gBZS5R7czHj3ehSgOzkVeyfuGrBw/WLm0hsAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-user-b {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAJpJREFUKBVjYKA1YES3wMTExBMoNgsqnnbmzJntyGqYkDlQ9qz////LgDCQD9MIV4ZNA1wSGwObhjRGRsYnIAzUkIZNE0licE+bm5tr/fnzJx1osjPQBFmoKY+BftnLwsIy8+TJk9dAYmANxsbGoUD2YiBmBwliAT+BYrFnz55dDfNDO1AAl2KQfpAcSA0DTIMyiEMAEKMG0wgAolIjcM7Tjm8AAAAASUVORK5CYII=) no-repeat;
    top: -4px;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-edit {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAMdJREFUKBVjYCARMOJTb2xsLMfIyBjLysq64Pjx409BapnwaQDKzf7//3/L79+/D1tbW0uB1LJg02BmZqYIVPgdKBf/79+//UC2xs+fP8OB/H4MG0CK//79ewCkEGQYExOTI5DawMnJuQTER/EDTDFQXA4kCQQ3QBpOnz79AsJF8gMWxTA1KDTYBhyKMUwH6WSysrKSB7kZyIY5AySOVTFIggno+5VAmijFYA1AwhzEgAKcJsMUwIMVGKPH2NnZ7ZFDBKYImQYAuO5YIMgk39gAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAFhJREFUKBVjYCARMKKrNzEx2fr//38vkDgjI+O2M2fOeCOrAWtAVoQsicyGaWZCFsTHBtr6H588Tjm4H4yNjfGacPbsWbBaop0Es3JYaQBFDMxjWOitMDEA3EEZfFEISwUAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-arrow-solid-top {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIFJREFUKBVjYBj+gBmXF2NiYiTV1dV5rl279gWbGiZsgg0NDSw/f/5cCZRbBWJjU4PVRjExsR6g4nAgln/z5g3v1atXd6JrxtAYHh4e+v///z4khZa6urrXgJqvIYkxMCJzgJo0//37dwooxoMsDmR/YWJiMlu5cuV1NPFRLrYQAADMVCaUtbG7XwAAAABJRU5ErkJggg==) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-milestone {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAGFJREFUKBVjYCARMILU/3dw+I+hj5FxG+P+/d7o4rg1IKtE0syELI6T/f+/F0yOOA0w1UCa9hpYkGxjYDxwABwIILH/jo5bGWBuZ2TcClOHogEmCKKxBSlInPZ+ANlCEgAA37EU4AHbBQUAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-arrow-left {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAHCAYAAAAvZezQAAAAAXNSR0IArs4c6QAAAFZJREFUCB1jZICCyspK83///hUxgvhVVVV6f//+3c3ExJTMVFNTowqU2cHMzJzf3t6+hen379/zgIp2t7W1rQCpZmJlZU0C0q5AbREgAQwzwAIgGZgtADMCJqH/QyvhAAAAAElFTkSuQmCC) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-arrow-right {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAHCAYAAAAvZezQAAAAAXNSR0IArs4c6QAAAFxJREFUCB1jKC8vX1lZWWnOAAVMQLD4379/m6qqqvRAYowgAsiJAAr2sbCw2IMFQIIVFRUL////r8SCpMKVlZXVnhFooA9Q+VxmZmbXtra2S0xATizQYD8QB6QaAJMLJ9BqE9yTAAAAAElFTkSuQmCC) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-handle-y {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAICAMAAADp7a43AAAACVBMVEX///////////+OSuX+AAAAA3RSTlMrQJG5H4EIAAAAEUlEQVR4AWNgYmRiZABB/CwAAtgAIUTUNkMAAAAASUVORK5CYII=) center no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-checkbox-normal {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAADpJREFUKBVjPHfu3O5///65MJAAmJiY9jCcOXPmP6kApIeJBItQlI5qRAkOVM5o4KCGBwqPkcxEvhsAbzRE+Jhb9IwAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-checkbox-checked {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAMBJREFUKBWVkjEOwjAMRe2WgZW7IIHEDdhghhuwcQ42rlJugAQS54Cxa5cq1QM5TUpByZfS2j9+dlJVt/tX5ZxbS4ZU9VLkQvSHKTIGRaVJYFmKrBbTCJxE2UgCdDzMZDkHrOV6b95V0US6UmgKodujEZbJg0B0ZgEModO5lrY1TMQf1TpyJGBEjD+E2NPN7ukIUDiF/BfEXgRiGEw8NgkffYGYwCi808fpn/6OvfUfsDr/Vc1IfRf8sKnFVqeiVQfDu0tf/nWH9gAAAABJRU5ErkJggg==) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-ic-dropdown-arrow {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAHlJREFUKBVjYBgFOEOAEVkmPDxc89+/f6eAYjzI4kD2FyYmJrOVK1deh4kzwRggGiQBVJCELAZig8SQNYHEmEEEMrh69eo1HR0dfqCYJUickZGxf9WqVf3IakBsFBthklpaWmVA9mEQhrJhUoTp0NBQCRAmrHL4qgAAuu4cWZOZIGsAAAAASUVORK5CYII=) no-repeat;
  }
  
  .toastui-calendar-icon.toastui-calendar-open.toastui-calendar-ic-dropdown-arrow {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIFJREFUKBVjYBj+gBmXF2NiYiTV1dV5rl279gWbGiZsgg0NDSw/f/5cCZRbBWJjU4PVRjExsR6g4nAgln/z5g3v1atXd6JrxtAYHh4e+v///z4khZa6urrXgJqvIYkxMCJzgJo0//37dwooxoMsDmR/YWJiMlu5cuV1NPFRLrYQAADMVCaUtbG7XwAAAABJRU5ErkJggg==) no-repeat;
  }
  
  .toastui-calendar-ic-location-b {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAOZJREFUKBWVUT0KwjAUbkzGTuJWPYNDpV0cXD2ETuIRBK+gs4ubp3BwcXBp6eINBKWDgydoid9X8oKCggYeee/7S9IGwZ9LverTNO3Wdb2y1o6IK6WOWutFlmU30XmDE58hbgvpTA+Y+mJqCemS20jdG2N6LPYMICc6b5BrIG3ONBZ7CoVj7w0cfllGRDj+gKQpjt/iPU0ye/LkROcNANaoCUzjqqquIsBuHddAWoiyLO9RFHUwJ4JxR/qmKIqdYG9vCMNwCeIiJHuHecj/B0GSJBng7ifO+ErDPM8L4b7ucRzPWJ8ET1E7YC7tmi9qAAAAAElFTkSuQmCC) no-repeat;
    top: -4px;
  }
  
  .toastui-calendar-ic-state-b {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAHlJREFUKBVjYCARMCKrNzEx8QTyZ/3//18GJM7IyPgESKWdOXNmO4gPAkwQCk6CFQMVNoIwVOMsuCw6w9jY+D8Iw8TR+SBxsJOATtkKNM0LphAbDbRxG9Bp3mAnEVIMMgCmBt0P2AxHERusGkAhgOJQ7Jyt2IUJiAIAwwIn24FgmhkAAAAASUVORK5CYII=) no-repeat;
    top: -4px;
  }
  
  .toastui-calendar-ic-repeat-b {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAQpJREFUKBVjYCARMCKrNzU1tf/3718lUMwQiP8yMjKeYWJi6j516tRhExOThjNnzjTANQAFqv///98CVHQPSO8A0ixATa5AtjyQPgDETmfPngULMpiZmbn//fu3BSg4B6ggCyjxG8hm8PT0ZH/9+vUJoJgTiA8CTCACqDgXaOJ9Xl7eTJhikDhQcSVQsQGITT8A9rSxsfF/mJVApzWCQgPGd3BwYPny5cstoNOuAZ3rAwoJOAAqviAqKtoOEwAaxPr58+dpQL4iEGeAxJFt2AfkOwA1PQTSu4Em/gGyPYC0EpCuAdraCtIADiWgQCPQOmdmZmYHoNgVoCJfIB0CpG8DI84BphgoRjoAAAzgdELI91E5AAAAAElFTkSuQmCC) no-repeat;
    top: -4px;
  }
  
  .toastui-calendar-timegrid-time-column {
    font-size: 11px;
    height: 100%;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-hour-rows {
    display: inline-block;
    position: relative;
    height: 100%;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time {
    text-align: right;
    position: absolute;
    right: 5px;
    color: #333;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time.toastui-calendar-timegrid-time-past {
    font-weight: normal;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time.toastui-calendar-timegrid-time-first {
    line-height: normal;
    visibility: hidden;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time.toastui-calendar-timegrid-time-last {
    height: 0;
    visibility: hidden;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time .toastui-calendar-timegrid-time-label,
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time span {
    transform: translateY(-50%);
    position: absolute;
    right: 0;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-current-time .toastui-calendar-timegrid-day-difference {
    position: absolute;
    right: 0;
    bottom: 100%;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time-hidden {
    visibility: hidden;
  }
  
  .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-current-time {
    position: absolute;
    text-align: right;
    right: 5px;
    font-size: 11px;
    font-weight: normal;
    transform: translateY(-50%);
  }
  
  .toastui-calendar-timezone-labels-slot {
    display: table;
    table-layout: fixed;
    position: absolute;
    height: 40px;
    border-bottom: 1px solid #e9e9e9;
    background-color: #fff;
  }
  
  .toastui-calendar-timezone-labels-slot .toastui-calendar-timegrid-timezone-label {
    display: table-cell;
    background-color: #fff;
    font-size: 11px;
    border-right: 1px solid $anti-flash-white;
    vertical-align: middle;
    padding-right: 5px;
    text-align: right;
  }
  
  .toastui-calendar-timezone-labels-slot .toastui-calendar-timegrid-timezone-collapse-button {
    position: absolute;
    top: 2px;
    bottom: 2px;
    width: 10px;
    border: 1px solid #dddddd;
    border-left: none;
    background: transparent;
    cursor: pointer;
  }
  
  .toastui-calendar-timezone-labels-slot .toastui-calendar-timegrid-timezone-collapse-button .toastui-calendar-icon {
    width: 4px;
    height: 7px;
    transform: translateX(-50%);
  }
  
  .toastui-calendar-column {
    position: relative;
  }
  
  .toastui-calendar-column .toastui-calendar-gridline-half {
    position: absolute;
    width: 100%;
  }
  
  .toastui-calendar-column .toastui-calendar-grid-selection {
    position: absolute;
    right: 10px;
    left: 1px;
    padding: 3px;
  }
  
  .toastui-calendar-column .toastui-calendar-grid-selection .toastui-calendar-grid-selection-label {
    font-size: 11px;
    font-weight: bold;
  }
  
  .toastui-calendar-column .toastui-calendar-events {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  
  .toastui-calendar-panel.toastui-calendar-time {
    overflow-y: auto;
  }
  
  .toastui-calendar-timegrid {
    user-select: none;
    position: relative;
    height: 200%;
    min-height: 900px;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-scroll-area {
    position: relative;
    height: 100%;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-columns {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-columns .toastui-calendar-gridline-half {
    position: absolute;
    width: 100%;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-columns .toastui-calendar-column {
    display: inline-block;
    height: 100%;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator {
    position: absolute;
    left: 0;
    right: 0;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-left {
    position: absolute;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-marker {
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: -4px 0 0 -5px;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-today {
    position: absolute;
  }
  
  .toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-right {
    position: absolute;
    right: 0;
  }
  
  
  
  .toastui-calendar-event-background { position: absolute; }
  
  .toastui-calendar-event-time {
    position: absolute;
    overflow: hidden;
    cursor: pointer;
  }
  
  .toastui-calendar-event-time .toastui-calendar-travel-time,
  .toastui-calendar-event-time .toastui-calendar-event-time-content {
    overflow: hidden;
    padding: 1px 0 0 3px;
    font-size: 12px;
  }
  
  .toastui-calendar-resize-handler-x {
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    height: 8px;
    text-align: center;
    color: #fff;
    cursor: row-resize;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAMAAACEE47CAAAACVBMVEX///////////+OSuX+AAAAA3RSTlMrQJG5H4EIAAAAEUlEQVR4AWNgYoRABhjEFAEAArQAIcHQcPsAAAAASUVORK5CYII=) no-repeat center bottom;
  }
  
  .toastui-calendar-weekday-event-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;
    font-weight: bold;
    font-size: 12px;
  }
  
  .toastui-calendar-weekday-event-dot {
    position: relative;
    top: 8px;
    float: left;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  
  .toastui-calendar-weekday-event-dot + .toastui-calendar-weekday-event-title {
    color: #333;
  }
  
  .toastui-calendar-weekday-resize-handle {
    position: absolute;
    top: 0;
    right: 5px;
  }
  
  .toastui-calendar-weekday-resize-handle.toastui-calendar-handle-y {
    cursor: col-resize;
  }
  
  .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #00B4E6;
    border-radius: 50%;
    font-weight: bold;
    margin-left: 2px;
  }
  
  
  
  .toastui-calendar-panel-title {
    display: table;
    float: left;
    height: 100%;
    padding-right: 5px;
  }
  
  .toastui-calendar-panel-title .toastui-calendar-left-content {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    font-size: 11px;
  }
  
  .toastui-calendar-panel-grid-wrapper {
    position: relative;
    overflow-y: hidden;
  }
  
  .toastui-calendar-panel .toastui-calendar-panel-title,
  .toastui-calendar-panel .toastui-calendar-panel-grid-wrapper {
    height: 100%;
  }
  
.toastui-calendar-allday-panel {
  position: relative;
  height: 100%;
  overflow-y: hidden;
}

.toastui-calendar-allday-panel .toastui-calendar-grid-selection {
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 0;
}
  
  .toastui-calendar-panel-grid {
    height: 100%;
    position: absolute;
  }
  
  .toastui-calendar-panel-event-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
  }
  
  .toastui-calendar-panel-event-wrapper .toastui-calendar-weekday-event-block {
    position: absolute;
  }
  
  .toastui-calendar-panel-event-wrapper .toastui-calendar-weekday-event {
    position: relative;
    margin: 0 10px 0 1px;
    border-left-style: solid;
    border-left-width: 3px;
  
    height: 18px;
    border-radius: 0;
    color: #9a1313;
    background-color: rgba(218, 27, 27, 0.2);
    border-color: #da1b1b;
  }
  
  .toastui-calendar-panel-event-wrapper .toastui-calendar-weekday-exceed-right .toastui-calendar-weekday-event {
    margin-right: 0;
  }
  
  .toastui-calendar-panel-event {
    position: absolute;
    border: 1px solid #333;
  }
  
  .toastui-calendar-weekday-exceed-in-week {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    margin-right: 5px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    padding: 1px 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #000;
  }
  
  .toastui-calendar-collapse-btn-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -1px -14px 0 -4px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #4f5959;
  }
  
  .toastui-calendar-day-view .toastui-calendar-panel:not(.toastui-calendar-time),
  .toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time) {
    overflow-y: hidden;
  }
  
  
  
  .toastui-calendar-floating-layer {
    z-index: 1;
  }
  
  .toastui-calendar-floating-layer * {
    box-sizing: border-box;
  }
  
  .toastui-calendar-popup-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .toastui-calendar-popup-container {
    position: absolute;
    font-weight: 2.5;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    clear: both;
    z-index: 2;
  }
  
  .toastui-calendar-popup-section {
    font-size: 0;
    min-height: 40px;
  }
  
  .toastui-calendar-popup-button.toastui-calendar-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 0;
    border: none;
  }
  
  .toastui-calendar-popup-button.toastui-calendar-popup-confirm {
    float: right;
    width: 96px;
    height: 36px;
    border-radius: 40px;
    background-color: #ff6618;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    border: none;
  }
  
  .toastui-calendar-dropdown-menu {
    position: absolute;
    width: 100%;
    top: 31px;
    z-index: 1;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-top: none;
    border-radius: 0 0 2px 2px;
  }
  
  .toastui-calendar-dropdown-menu.toastui-calendar-open {
    display: block;
  }
  
  .toastui-calendar-dropdown-menu-item {
    width: 100%;
    height: 30px;
    border: none;
    padding: 0 9px 0 12px;
    font-size: 0;
    border-radius: 2px;
    cursor: pointer;
  }
  
  .toastui-calendar-popup-arrow-border,
  .toastui-calendar-popup-arrow-fill {
    position: absolute;
  }
  
  .toastui-calendar-see-more-container {
    display: block;
    position: absolute;
    z-index: 1;
  }
  
  .toastui-calendar-see-more {
    height: inherit;
    padding: 5px;
  }
  
  .toastui-calendar-more-title-date {
    font-size: 23px;
    color: #333;
  }
  
  .toastui-calendar-more-title-day {
    font-size: 12px;
    color: #333;
  }
  
  .toastui-calendar-month-more-list {
    overflow: auto;
    padding: 0 17px;
  }
  
  .toastui-calendar-see-more-header {
    position: relative;
    border-bottom: none;
  }
  
  .toastui-calendar-form-container {
    min-width: 474px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    border: 1px solid #d5d5d5;
    padding: 17px;
    border-radius: 2px;
  }
  
  .toastui-calendar-form-container .toastui-calendar-hidden-input {
    display: none;
  }
  
  .toastui-calendar-form-container .toastui-calendar-grid-selection {
    font-size: 11px;
    font-weight: bold;
  }
  
  .toastui-calendar-popup-section-item {
    height: 32px;
    padding: 0 9px 0 12px;
    border: 1px solid #d5d5d5;
    display: inline-block;
    font-size: 0;
    border-radius: 2px;
  }
  
  .toastui-calendar-popup-section-item input {
    border: none;
    height: 30px;
    outline: none;
    display: inline-block;
  }
  
  .toastui-calendar-popup-section-item .toastui-calendar-content {
    text-align: left;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    position: relative;
    padding-left: 8px;
  }
  
  .toastui-calendar-popup-date-picker .toastui-calendar-content {
    max-width: 125px;
  }
  
  .toastui-calendar-dropdown-section {
    position: relative;
  }
  
  .toastui-calendar-dropdown-section.toastui-calendar-calendar-section {
    width: 176px;
  }
  
  .toastui-calendar-dropdown-section .toastui-calendar-content {
    line-height: 30px;
  }
  
  .toastui-calendar-popup-section-title input {
    width: 365px;
  }
  
  .toastui-calendar-dot {
    border-radius: 8px;
    width: 12px;
    height: 12px;
    margin: 1px;
  }
  
  .toastui-calendar-content.toastui-calendar-event-calendar {
    width: 125px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    top: -1px;
  }
  
  .toastui-calendar-popup-section-location .toastui-calendar-content {
    width: 400px;
  }
  
  .toastui-calendar-popup-section-allday {
    border: none;
    padding: 0 0 0 8px;
    cursor: pointer;
  }
  
  .toastui-calendar-popup-section-allday .toastui-calendar-ic-checkbox-normal {
    display: inline-block;
    cursor: pointer;
    line-height: 14px;
    margin: 0;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  
  .toastui-calendar-popup-section-allday .toastui-calendar-content {
    padding-left: 4px;
  }
  
  .toastui-calendar-popup-date-picker {
    width: 176px;
  }
  
  .toastui-calendar-datepicker-container > div {
    z-index: 1;
  }
  
  .toastui-calendar-popup-date-dash {
    font-size: 12px;
    color: #d5d5d5;
    height: 32px;
    padding: 0 4px;
    vertical-align: middle;
  }
  
  .toastui-calendar-popup-button {
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 2px;
    text-align: center;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    color: #333;
  }
  
  .toastui-calendar-popup-button.toastui-calendar-popup-section-private {
    height: 32px;
    padding: 8px;
    font-size: 0;
    margin-left: 4px;
  }
  
  .toastui-calendar-popup-button .toastui-calendar-event-state {
    width: 58px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .toastui-calendar-dropdown-section.toastui-calendar-state-section {
    width: 109px;
  }
  
  .toastui-calendar-dropdown-section.toastui-calendar-state-section .toastui-calendar-popup-button {
    width: 100%;
  }
  
  .toastui-calendar-state-section .toastui-calendar-content {
    width: 58px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .toastui-calendar-popup-section-item.toastui-calendar-dropdown-menu-item {
    display: block;
    height: 30px;
    border: none;
    cursor: pointer;
  }
  
  .toastui-calendar-dropdown-menu-item .toastui-calendar-content {
    display: inline-block;
    position: relative;
    padding-left: 8px;
    text-align: left;
    font-size: 12px;
    vertical-align: middle;
  }
  
  .toastui-calendar-popup-section-item.toastui-calendar-popup-button {
    height: 32px;
    font-size: 0;
    top: -1px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-top .toastui-calendar-popup-arrow-border {
    border-top: none;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #d5d5d5;
    border-left: 8px solid transparent;
    left: calc(50% - 8px);
    top: -7px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-top .toastui-calendar-popup-arrow-fill {
    border-top: none;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    left: -7px;
    top: 1px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-bottom .toastui-calendar-popup-arrow-border {
    border-top: 8px solid #d5d5d5;
    border-right: 8px solid transparent;
    border-bottom: none;
    border-left: 8px solid transparent;
    bottom: -7px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-bottom .toastui-calendar-popup-arrow-fill {
    border-top: 7px solid #fff;
    border-right: 7px solid transparent;
    border-bottom: none;
    border-left: 7px solid transparent;
    left: -7px;
    bottom: 1px;
  }
  
  .toastui-calendar-detail-container {
    width: 301px;
    min-width: 301px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    border: solid 1px #d5d5d5;
    padding: 17px 17px 0;
    border-radius: 2px;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-section-header {
    margin-bottom: 6px;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-section-detail {
    margin-bottom: 16px;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-section-button {
    border-top: 1px solid $anti-flash-white;
    font-size: 0;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-content {
    height: 24px;
    font-size: 12px;
    line-height: 2;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-icon {
    width: 12px;
    height: 12px;
    background-size: 12px;
    position: relative;
    margin-right: 8px;
  }
  
  .toastui-calendar-detail-container .toastui-calendar-calendar-dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: -4px;
    margin-right: 10px;
  }
  
  .toastui-calendar-event-title {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.6;
    word-break: break-all;
  }
  
  .toastui-calendar-detail-item-indent {
    text-indent: -20px;
    padding-left: 20px;
  }
  
  .toastui-calendar-edit-button,
  .toastui-calendar-delete-button {
    display: inline-block;
    padding: 7px 9px 11px 9px;
    width: calc(50% - 1px);
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .toastui-calendar-vertical-line {
    background: $anti-flash-white;
    width: 1px;
    height: 14px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -7px;
  }
  
  .toastui-calendar-section-button .toastui-calendar-icon {
    margin-right: 4px;
    top: -3px;
  }
  
  .toastui-calendar-section-button .toastui-calendar-content {
    position: relative;
    top: 2px;
  }
  
  .toastui-calendar-popup-top-line {
    position: absolute;
    border-radius: 2px 2px 0 0;
    width: 100%;
    height: 4px;
    border: none;
    top: 0;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-left .toastui-calendar-popup-arrow-border {
    border-top: 8px solid transparent;
    border-right: 8px solid #d5d5d5;
    border-bottom: 8px solid transparent;
    border-left: none;
    left: -7px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-left .toastui-calendar-popup-arrow-fill {
    border-top: 7px solid transparent;
    border-right: 7px solid #fff;
    border-bottom: 7px solid transparent;
    border-left: none;
    top: -7px;
    left: 1px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-right .toastui-calendar-popup-arrow-border {
    border-top: 8px solid transparent;
    border-right: none;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #d5d5d5;
    right: -7px;
  }
  
  .toastui-calendar-popup-arrow.toastui-calendar-right .toastui-calendar-popup-arrow-fill {
    border-top: 7px solid transparent;
    border-right: none;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff;
    top: -7px;
    right: 1px;
  }
  
  
  
  .toastui-calendar-day-names {
    position: relative;
  }
  
  .toastui-calendar-day-name-container {
    position: relative;
  }
  
  .toastui-calendar-day-name-item {
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    padding: 0 10px;
    
  }
  
  .toastui-calendar-day-name-item.toastui-calendar-week {
    line-height: 38px;
    height: 42px;
  }
  
  .toastui-calendar-day-name-item.toastui-calendar-month {
    line-height: 31px;
    height: 31px;
  }
  
  .toastui-calendar-day-view-day-names,
  .toastui-calendar-week-view-day-names {
    border-bottom: 1px solid $anti-flash-white;
  }
  
  .toastui-calendar-day-names.toastui-calendar-week {
    height: 42px;
    padding-left: 0;
    text-align: center;
  }
  
  .toastui-calendar-day-names.toastui-calendar-month {
    height: 31px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }
  
  .toastui-calendar-day-name__date {
    font-size: 26px;
  }
  
  .toastui-calendar-day-name__name {
    font-size: 12px;
  }
  
  .toastui-calendar-layout.toastui-calendar-month {
    height: 100%;
  }
  
  .toastui-calendar-month .toastui-calendar-day-names {
    /* from constant MONTH_DAY_NAME_HEIGHT */
    height: 31px;
  }
  
  .toastui-calendar-month .toastui-calendar-month-daygrid {
    position: relative;
    /* modify this if you want to change height of day names */
    height: calc(100% - 31px);
  }
  
  .toastui-calendar-month-week-item {
    position: relative;
  }
  
  .toastui-calendar-weekday-grid {
    position: absolute;
    min-height: inherit;
    width: 100%;
    height: 100%;
  }
  
  .toastui-calendar-daygrid-cell {
    position: absolute;
    height: 100%;
    min-height: inherit;
    padding: 3px 0;
  }
  
  .toastui-calendar-daygrid-cell + .toastui-calendar-daygrid-cell {
    border-left: 1px solid $anti-flash-white;
  }
  
  .toastui-calendar-grid-cell-date {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 1.7;
    text-align: center;
  }
  
  .toastui-calendar-grid-cell-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  
  .toastui-calendar-grid-cell-more-events {
    float: right;
    height: 27px;
    line-height: 27px;
    padding: 0 5px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: #aaa;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .toastui-calendar-weekday-events {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  
  /* TODO: define this class in more common place */
  .toastui-calendar-weekday-event {
    display: block;
  }
  
  .toastui-calendar-weekday {
    height: 100%;
  }
  
  .toastui-calendar-weekday .toastui-calendar-grid-selection {
    position: absolute;
  }
  
  
  
  
  
  /* CUSTOM */

  .toastui-calendar-template-alldayTitle{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    font-size: 11px;
  }
.toastui-calendar-template-allday {
  width: fit-content;
}