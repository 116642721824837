.recovery-password-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 70px 80px;
  background-color: #fff;

  @media (max-width: 991px) {
    padding: 20px 20px;
  }
}

.recovery-password-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.recovery-password-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.title {
  color: var(--Text, #1b1b1b);
  text-align: center;
  font: 25px Open Sans, sans-serif;
  margin: 0;
}

.description {
  color: var(--Text, #1b1b1b);
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 25px 0 30px;
}

.recovery-password-content .label {
  color: #1b1b1b;
  font: 16px Open Sans, sans-serif;
  margin-bottom: 12px;
}

.recovery-password-content .input {
  width: 100%;
  padding: 14px 16px;
  border-radius: 10px;
  background-color: var(--Anti-flash-white, #eee);
  color: var(--Dim-gray, #747578);
  font: 400 16px Open Sans, sans-serif;
  border: none;
}

.recovery-password-form .submit-button {
  width: 100%;
  padding: 10px;
  margin: 12px 0 111px;
  border-radius: 10px;
  background-color: var(--Process-Cyan, #00b4e6);
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-feature-settings: "clig" off, "liga" off;
  cursor: pointer;
  border: none;

  @media (max-width: 991px) {
    margin-bottom: 40px;
    white-space: initial;
  }
}

.recovery-password-form .submit-button:hover {
  cursor: pointer;
  background-color: #0078a8;
}

.recovery-password-confirmation-message {
  align-items: center;
  background-color: #fff;
  color: var(--Text, #1b1b1b);
  display: flex;
  flex-direction: column;
  padding: 78px 80px;
  text-align: center;
  width: 100%;
}

.recovery-password-email {
  font-weight: 700;
}
