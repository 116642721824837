@use '../../../../styles/variables' as *;

.tag {
    font-family: $font-family-base;
    background-color: $process-cyan;
    color: $white;
    padding: 4px 8px;
    border-radius: 12px;
    height: 30px;
    display: flex;
    align-items: center;
}

.removeTagButton {
    background: none;
    border: none;
    color: $white;
    margin-left: 4px;
    cursor: pointer;
}
