.simple-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.help-text {
  color: var(--Eerie-Black, #1b1b1b);
  margin-top: 39px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.help-link {
  color: var(--Process-Cyan, #00b4e6);
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  padding: 4px 60px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
}
