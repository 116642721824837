@use '../../../styles/variables' as *;

.customInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $anti-flash-white;
    background-color: $anti-flash-white;

    &.error {
        border-color: $red;
        color: $red;

        .inputField::placeholder {
            color: $red;
        }
    }

    .errorIcon {
        color: $red;
    }

    &:focus-within {
        border-color: $process-cyan;
        background-color: $seasalt;
        outline: none;
    }

    textarea {
        height: 140px;
        padding: 10px;
    }

    .inputField {
        width: 90%;
        border: none;
        background: none;
        font-size: 14px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        resize: none;
        min-height: 35px;

        &::placeholder {
            color: $dim-gray;
        }

        &:focus {
            outline: none;
        }
    }

    .disabledInput {
        color: $dim-gray;
        cursor: not-allowed;
    }

    .icon {
        display: flex;
        align-items: center;
    }

    .inputIcon {
        color: $dim-gray;
        cursor: pointer;
    }

    .tooltip {
        position: relative;
        display: inline-block;

        .tooltiptext {
            visibility: hidden;
            width: auto;
            max-width: 300px;
            background: $white;
            color: $red;
            text-align: left;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid $alice-blue;
            box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
            position: absolute;
            z-index: 99;
            bottom: 150%;
            transform: translateX(-94%);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        &:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
}
