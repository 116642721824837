@use '../../../styles/variables' as *;

.notificationAllWrapper {
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
    color: $white;

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    max-height: 2.5vh;
}

.backIcon {
    font-size: 24px;
    color: $eerie-black;
    cursor: pointer;
}

.notificationsList {
    display: flex;
    flex-direction: column;
    max-height: 81.5vh;
    overflow-y: auto;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }
}

.notification {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $dark-gray;
}

.notification:last-child {
    border-bottom: none;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.notificationDetails {
    flex-grow: 1;
}

.notificationTime {
    font-size: 12px;
    color: $dim-gray;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.notificationTime span {
    font-family: $font-family-base;
}

.notificationMessage {
    font-size: 14px;
    color: $anti-flash-white;
}

.blueDot {
    width: 8px;
    height: 8px;
    background-color: $process-cyan;
    border-radius: 50%;
    margin-left: auto;
}

.emptyNotificationMessage {
    font-size: 16px;
    color: $dim-gray;
    text-align: center;
    padding: 20px 0;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  