@use '../../../styles/variables' as *;

.avatar {
    aspect-ratio: 1;
    margin: auto 0;
    position: relative;
}

.avatar-circular {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-circular span { 
    color: $white;    
    font-family: $font-family-base;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
}

.avatar-lite {
    width: 40px;
    height: 40px;
}

.avatar-medium {
    width: 60px;
    height: 60px;
}

.avatar-hard {
    width: 80px;
    height: 80px;

    span {
        font-size: 50px;
    }
}

.avatar-very-hard {
    width: 150px;
    height: 150px;
    span {
        font-size: 60px;
    }
    .avatar-status {
        right: 8px;
        bottom: 8px;
    }
}

.avatar-status {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-icon-container {
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-status .online {
    color: $kelly-green;
}

.avatar-status .busy {
    color: $red;
}

.avatar-status .offline {
    color: $gray;
}

.avatar img {
    border-radius: 50%;
}
