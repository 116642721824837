@use '../../../styles/variables' as *;

.messages {
    display: flex;
    width: 580px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-family: $font-family-base;
    font-size: 16px;
    color: $eerie-black;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
}

.title {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 23px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 140%;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $process-cyan;
}

.icon {
    width: 32px;
    height: 32px;
    color: $white;
}
