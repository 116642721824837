@use '../../../../styles/variables' as *;

.createButton {
    font-size: 14px;
    color: $white;
    border-radius: 5px;
    font-weight: bold;
    background-color: $process-cyan;
    border: none;
    cursor: pointer;
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    width: 380px;
}
