@use '../../../../styles/variables' as *;

.tableContainer {
  font-family: $font-family-base;
  overflow-y: auto;
  height: 71dvh;
  max-height: 71dvh;


  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }

  .homeTable {
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    gap: 25px;

    th {
      color: $eerie-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      &.sorting:hover {
        cursor: pointer;
      }
    }

    td {
      padding: 8px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: $eerie-black;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;
    }

    .homeTitle {
      color: $dim-gray;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }

    .homeId {
      color: $eerie-black;
      font-size: 14px;
      font-weight: 600;
      line-height: 140%;
    }
  }

}

.status {
  .statusGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    margin-right: 0;
    gap: 10px;
  }

  .statusText {
    width: 95px;
    span{
      color: $eerie-black;
      font-size: 14px;
    }

  }

  .switch {
    margin-left: 10px;
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: $white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $green-1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $green-1;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.dropdownContainer {
  position: relative;
  text-align: right;

  .dropIcon {
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.dropdownMenu {
  display: flex;
  width: 130px;
  height: 90px;
  padding: 20px 10px;
  position: absolute;
  bottom: 0px;
  top: 30px;
  right: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6px;
  z-index: 3;
  border-radius: 5px;
  border: 1px solid $alice-blue;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

  button {
    display: block;
    width: 100%;
    padding: 6px 10px;
    background: none;
    border-radius: 5px;
    border: none;
    text-align: left;
    cursor: pointer;

    .iconButton {
      margin-right: 10px;
    }

    &:hover {
      background: rgba($process-cyan, 0.12);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-duration: 300ms;

      .iconButton {
        color: $process-cyan;
      }
    }
  }
}
