@use '../../../styles/variables' as *;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  padding: 25px 33px;
  max-width: 1500px;
  min-width: 580px;
  width: auto;
  border-radius: 5px;
  border: 0.75px solid $anti-flash-white;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.iconContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  margin-bottom: 10px;
}

.icon {
  display: block;
  width: 100%;
  height: auto;
}

.title {
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
  text-align: center;
}


.modalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.acceptIcon {
  margin-right: 8px;
}

.iconSuccess {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  color: $kelly-green;
}