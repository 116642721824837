@use '../../../styles/variables' as *;

.popupContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 999;
  width: 350px;

}
.info{
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}
.notificationPopup {
  padding: 20px 30px;
  width: 100%;
  border-radius: 5px;
  border: 0.75px solid $anti-flash-white;
  background: $blue-light;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.infoTitle {
  color: $process-cyan;
  font-family: $font-family-base;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.infoIcon{
  color: $process-cyan;
  font-size: 20px;
}

.popupTitle {
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.popupBody {
  margin-top: 15px;
  color: $eerie-black;
  font-size: 14px;
  line-height: 1.4;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 20px;
}

.popupImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  margin-bottom: 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: $black;

  &:hover {
    color: $process-cyan;
  }

  &:focus {
    outline: none;
  }
}

.avatar {
  display: flex;
  justify-content: center;
  margin: 10px 0px 15px 0px;
}
