.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.login-page {
  display: flex;
  justify-content: center;
  background-image: url('../img/background.png');
  background-size: cover;  
  height: 100vh !important;
  background-position: center;
}

.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100% !important;
  padding: 26px 50px 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  .login-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

.background-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1100px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.logo {
  width: 143px;
  max-width: 100%;
  aspect-ratio: 3.7;
  object-fit: contain;
  object-position: center;
}

.login-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 480px;
  max-width: 100%;
  padding: 36px 40px;
  background-color: #fff;
  border-radius: 5px;
}


.login-title {
  align-self: stretch;
  color: var(--Eerie-Black, #1b1b1b);
  font: 32px/45px Poppins, sans-serif;
}

.login-description {
  margin-top: 32px;
  color: #1b1b1b;
  text-align: center;
  font-family: Open Sans, sans-serif;
}

.login-form .input {
  width: 330px;
  max-width: 100%;
  margin-top: 12px;
  padding: 14px 16px;
  color: var(--Dim-gray, #747578);
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  background-color: var(--Anti-flash-white, #eee);
  border-radius: 10px;
  border: none;
}

@media (max-width: 991px) {
  .input {
    padding-right: 20px;
  }
}

.login-button {
  width: 330px;
  max-width: 100%;
  margin-top: 12px;
  padding: 10px 60px;
  color: #fff;
  font: 500 14px Poppins, sans-serif;
  text-align: center;
  background-color: var(--Process-Cyan, #00b4e6);
  border-radius: 10px;
  border: none;
}
.login-button:hover {
  cursor: pointer;
  background-color: #0078a8;
}

.forgot-password {
  width: 330px;
  max-width: 100%;
  margin-top: 30px;
  padding: 5px 60px;
  color: var(--Process-Cyan, #00b4e6);
  font: 500 12px Poppins, sans-serif;
  text-align: center;
}

.login-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.help-text {
  color: var(--Eerie-Black, #1b1b1b);
  margin-top: 39px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.help-link {
  color: var(--Process-Cyan, #00b4e6);
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  padding: 4px 60px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
}

.copyright {
  color: var(--White, #fff);
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 1%;
}
