@use '../../../styles/variables' as *;

.notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid $gray;
    cursor: pointer;
    width: 100%;
    color: $eerie-black;
}

.column1 {
    display: flex;
    flex-grow: 1;
}

.column2 {
    color: $dim-gray;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: right;
    font-family: $font-family-base;
    flex: 0 0 auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.blueDot {
    width: 10px;
    height: 10px;
    background-color: $process-cyan;
    border-radius: 50%;
    margin-left: auto;
}

.notificationContent {
    display: flex;
    gap: 8px;
}

.avatar {
    width: 40px;
    height: 40px;
    aspect-ratio: 1;
    margin: auto 0;

    &.avatarCircular {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        span {
            font-size: 18px;
            font-weight: bold;
            color: $white;
        }
    }
}

.notificationDetails {
    display: flex;
    flex-direction: column;
    padding: 1px 0;
    justify-content: center;
}

.notificationTime {
    display: flex;
    gap: 10px;    
    align-items: center;
    font-size: 9px;
    color: $dim-gray;
    font-weight: 400;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    span {
        font-family: $font-family-base;
        line-height: 1.4;
    }
}

.notificationMessage {
    color: $eerie-black;
    font: 600 14px/1.43 $font-family-base;
    text-align: left;
}
.notificationBody{
    color: $dim-gray;
    font: 600 12px/1.43 $font-family-base;
    text-align: left;
}
.highlighted {
    background-color: $blue-light80;

    &:last-child {
        border-bottom: 1px solid $gray;
    }

    .avatar {
        fill: $white;
    }
}
