@use '../../../../styles/variables' as *;

.homeUserTreeContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 800px;
}

.searchGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: 0.75px solid $gray;
  border-radius: 5px;
  transition: border-color 0.3s;
  margin-bottom: 0;

  &.active {
    border-color: $process-cyan;
  }

  &:focus-within {
    border-color: $process-cyan;

    .searchIcon {
      color: $process-cyan;
    }
  }
}

.searchInput {
  flex: 1;
  border: none;
  outline: none;
  background: none;
  height: 100%;
}

.searchIcon {
  color: $gray;
  pointer-events: none;
  transition: color 0.3s;
}

.searchContainer.active  {
  color: $process-cyan;
}

.searchButton {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background-color: $blue;
    border-color: $blue;
  }
}

.tableContainer {
  overflow-y: auto;
  max-height: 40vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }

  .usersTable {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 10px 15px;
      border-bottom: 1px solid $anti-flash-white;
      vertical-align: middle;
      font-size: 14px;
      color: $eerie-black;
    }

    th {
      font-weight: 600;
      background-color: $b-light;
    }

    .checkbox {
      margin: 0;
    }

    .homeRow {
      background-color: $white;
      cursor: pointer;

      &:hover {
        background-color: $seasalt-background;
      }

      .icon {
        transition: transform 0.3s ease;
        color: $dim-gray;

        &.open {
          transform: rotate(90deg);
        }
      }
    }

    .userRow {
      background-color: $seasalt-background;
      &:hover {
        background-color: $seasalt;
      }
      td {
        padding-left: 15px;
      }
    }

    .noUsersRow {
      background-color: $seasalt-background;

      .noUsersMessage {
        text-align: center;
        padding: 10px 0px;
        font-size: 14px;
        color: $dim-gray;
      }
    }
  }
}


.checkbox {
  width: 18px;
  height: 18px;
  accent-color: $process-cyan;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.75px solid $gray;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  &:checked {
    background-color: $process-cyan;
    border-color: $process-cyan;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.checkboxUser {
  width: 18px;
  height: 18px;
  accent-color: $process-cyan;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.75px solid $gray;
  border-radius: 3px;
  position: relative;
  left: 30px;
  cursor: pointer;
  transition: all 0.3s;

  &:checked {
    background-color: $process-cyan;
    border-color: $process-cyan;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.noHomesMessage {
  text-align: center;
  color: $dim-gray;
  font-size: 16px;
  padding: 20px;
}

.noUsersRow {
  background-color: $seasalt-background;

  .noUsersMessage {
    text-align: center;
    padding: 10px 0px;
    font-size: 14px;
    color: $dim-gray;
  }
}

.selectedUsersCount {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.confirmButton {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background-color: $blue;
    border-color: $blue;
  }
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    color: $white;
    background-color: $process-cyan;
  }
}
