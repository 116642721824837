@use '../../../../styles/variables' as *;

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.name {
    color: $black;
    text-align: center;
    max-width: 100%;
    line-height: 140%;
    font-family: $font-family-base;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
}

.status {
    font-size: 14px;
    color: $dim-gray;
    display: flex;
    align-items: center;
}

.videocallButton {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    padding: 8px 0px;
    background-color: $emerald;
    border: 1px solid $emerald;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $b-light;
    text-align: center;
    gap: 15px;
    cursor: pointer;
}

.videocallButton:hover {
    background-color: $kelly-green;
}

.videocallText {
    font-family: $font-family-base;
}

.modalTitle {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 23px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 140%;
}

.modalAcceptButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
}

.imageCollage {
    display: grid;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
}

.collageImage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $white;
    font-weight: bold;
}

.imageCollage.members-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.imageCollage.members-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.imageCollage.members-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "a b"
        "c c";
}

.imageCollage.members-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.imageCollage.members-1 .collageImage:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.imageCollage.members-2 .collageImage:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.imageCollage.members-2 .collageImage:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.imageCollage.members-3 .collageImage:nth-child(1) {
    grid-area: a;
}

.imageCollage.members-3 .collageImage:nth-child(2) {
    grid-area: b;
}

.imageCollage.members-3 .collageImage:nth-child(3) {
    grid-area: c;
}

.liveIndicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    margin-right: 8px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.members {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    align-items: center;
}

.membersList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  
    @media (max-width: 1600px) {
      max-height: 220px;
    }
  
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  
    &::-webkit-scrollbar {
      width: 12px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      border-radius: 6px;
      border: 3px solid transparent;
      background-clip: content-box;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: $dim-gray;
    }
  }
  
  .membersListThreeColumns {
    grid-template-columns: repeat(3, 1fr);
  
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  

.memberItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
}

.memberInfo {
    display: flex;
    flex-direction: column;
}

.memberName {
    font-weight: 600;
    color: $black;
}

.memberEmail {
    font-size: 14px;
    color: $dim-gray;
}
