@use '../../../../styles/variables' as *;

.side{
    border-right: 0.75px solid $anti-flash-white;
    height: 100%;
    width: 250px;

}

.header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
    background-color: $blue-light80;


}

.sideCalendar {
    padding: 20px;
}

.navButtonLeft {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
}

.navButtonRight {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
}

.month {
    font-size: 16px;
    font-weight: bold;
}

.today {
    color: $eerie-black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.todayLabel {
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.date {
    margin-left: 5px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.toggleIcon {
    transition: transform 0.3s ease;
    width: 24px;
    height: 24px;
}

.toggleIcon.open {
    transform: rotate(180deg);
}

.eventsList {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    margin-bottom: 20px;
}

.eventItem {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(100%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--category-color);
    }
}

.time {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    display: inline-block;
    vertical-align: middle;
}

.description {
    color: $dim-gray;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    
}

.iconVideo {
    color: $white;
    border-radius: 100px;
    background: $gray;
    padding: 2px;
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

.link {
    color: $process-cyan;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.channels {
    margin-top: 20px;
}

.channelList {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.channel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: var(--category-color);
    }
}

.name {
    font-size: 14px;
    color: $eerie-black;
}

.channelTitle {
    color: $eerie-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 10px;
}

.noEventsMessage{
    color: $dim-gray;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
}