@use '../../../../styles/variables' as *;

.agendaView {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    max-height: 85dvh;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
    background: transparent;
    }

    &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: users-box;
    }

    &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
    }
    table {
        border-left: 0.5px solid $anti-flash-white;
        border-right: 0.5px solid $anti-flash-white;
        border-collapse: collapse;

    }
    th {
        border: 0.5px solid $anti-flash-white;
        border-right: none;
        border-left: none;
    }
    tr {
        border-bottom: 0.5px solid $anti-flash-white;
    }
}

.agendaTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
}

.agendaTable th {
    padding: 15px;
    color: $dim-gray;
    font-weight: normal;
}

.agendaTable td {
    padding: 15px;
    vertical-align: top;
}

.dateHeader {
    display: flex;
    align-items: center;
    gap: 10px;

    &.today {
       .dateCircle{
            background-color: $process-cyan;
            color: $white;
       }
       .month, .dayName {
            color: $process-cyan;
       }
    }
}

.dateCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $eerie-black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
}

.month, .dayName {
    font-size: 14px;
    color: $dim-gray;
}

.dayName {
    font-weight: bold;
}

.noEvents {
    font-size: 14px;
    color: $dim-gray;
    display: flex;
    vertical-align: middle;
}

.eventTimeList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.eventTime {
    position: relative;
    padding-left: 20px;
    margin-bottom: 5px;
    color: $eerie-black;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--event-color);
    }
}

.eventList {
    list-style-type: circle;
    padding: 0;
    margin: 0;
}

.eventItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.eventIcon {
    margin-right: 10px;
}

.eventCategory {
    margin-left: 8px;
    color: $eerie-black;
    font-weight: bold;
}

.eventTitle {
    color: $eerie-black;
}
