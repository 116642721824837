@use '../../../../styles/variables' as *;

.tableContainer {
  font-family: $font-family-base;
  overflow-y: auto;
  height: 71dvh;
  max-height: 71dvh;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }
}

  .contentTable {
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    gap: 25px;
  }

    th {
      color: $eerie-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      &.sorting:hover {
        cursor: pointer;
      }
    }

    td {
      padding: 8px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: $eerie-black;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;
}

.contentInfo {
  display: flex;
  align-items: center;

  .imageContainer {
    position: relative;

    .contentImage {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

    .playIcon {
      position: absolute;
      bottom: -2px;
      right: 5px;
      border-radius: 50%;
      color: $kelly-green;

    }
    .loadingIndicator {
      width: 100%;
      height: 100%;
      display: flex;
      color: $blue;
      margin-right: 10px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .contentTitle {
    color: $dim-gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }

  .contentDescription {
    color: $eerie-black;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: auto;
    max-width: 300px;
    background: $white;
    color: $process-cyan;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $alice-blue;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 3;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}


.link {
  color: $process-cyan;
  text-decoration: none;
}

.status {
  .statusGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-shrink: 0;
  }

  .statusText {
    padding: 3px 0px;
    border-radius: 2px;
    font-size: 10px;
    text-align: center;
    font-style: normal;
    vertical-align: middle;
    font-weight: 600;
    line-height: 140%;
    color: $white;
    width: 80px;
    height: 20px;

    .statusIcon {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
  }

  &.active .statusText {
    background-color: $green-1;
  }

  &.inactive .statusText {
    background-color: $red;
  }

  &.live .statusText {
    background-color: $orange;
  }
}

.dropdownContainer {
  position: relative;
  text-align: right;
  min-width: 60px;

  .dropIcon {
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.centerColumn {
  text-align: center;
  justify-content: center;
}

.dropdownMenu {
  display: flex;
  width: 130px;
  height: 90px;
  padding: 20px 10px;
  position: absolute;
  bottom: 0px;
  top: 30px;
  right: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6px;
  z-index: 3;
  border-radius: 5px;
  border: 1px solid $alice-blue;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

  button {
    display: block;
    width: 100%;
    padding: 6px 10px;
    background: none;
    border-radius: 5px;
    border: none;
    text-align: left;
    cursor: pointer;

    .iconButton {
      margin-right: 10px;
    }

    &:hover {
      background: rgba($process-cyan, 0.12);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-duration: 300ms;

      .iconButton {
        color: $process-cyan;
      }
    }
  }
}
.liveBackground {
  background: #e6f4ff80;
}
