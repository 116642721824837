@use '../../../styles/variables' as *;

.header {
    display: flex;
    align-items: center;
    background-color: $process-cyan;
    padding: 5px 20px 0px;
    height: 7.5vh;
}

.videocallContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 7.5vh);
    width: 100%;
}

.videocall {
    height: calc(100vh - 7.5vh);
    width: 100%;
    border: none;
    position: absolute;
    top: 7.5vh;
}

.messageContainer {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.countdown {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.countdownItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background-color: $anti-flash-white;
    border-radius: 10px;
    width: 100px;
    height: 100px;
    justify-content: center;
}

.countdownNumber {
    font-size: 2.5em;
    font-weight: bold;
    color: $eerie-black;
}

.countdownLabel {
    font-size: 1em;
    color: $eerie-black;
    margin-top: 5px;
    text-transform: uppercase;
}

.meetingTitle {
    color: $eerie-black;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 140%;
}

.meetingImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .countdown {
        gap: 10px;
    }

    .countdownItem {
        width: 60px;
        height: 80px;
    }

    .countdownNumber {
        font-size: 2em;
    }

    .meetingTitle {
        font-size: 1.5em;
    }
}

.logo {
    height: 55px;
}
