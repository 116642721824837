@use '../../../styles/variables' as *;

.dateTimeFieldContainer {
  font-family: $font-family-base;
  border: 1px solid $gray;
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 40px;

  &:hover {
    border-color: $process-cyan;
    color: $process-cyan;
    .labelText{
      color: $process-cyan;
    }
    outline: none;
  }
  &:focus-within {
    border-color: $process-cyan;
    color: $process-cyan;
    .labelText {
      color: $process-cyan;
    }
    .displayInput {
      color: $process-cyan;
    }
    outline: none;
  }

  &.err {
    border-color: $red;
    color: $red;

    .labelText {
      color: $red;
    }

    .displayInput {
      color: $red;
    }
  }
}

.dateTime {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.labelText {
  font-size: 10px;
  color: $dim-gray;
  margin-bottom: 1px;
  display: block;
  height: 15px;
}

.dateTimeField {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 15px;
  width: 100%;
}

.displayInput {
  border: none;
  padding: 5px 0px;
  font-size: 14px;
  color: $eerie-black;
  background-color: $white;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  height: 15px;

  &:focus {
    outline: none;
  }
}

.hiddenInput {
  position: absolute;
  bottom: -5px;
  right: 9px;
  width: 100%;
  height: 40px;
  opacity: 0%;
  pointer-events: none;
}

.iconGroup {
  display: flex;
  align-items: center;

  .tooltip {
    position: relative;
    display: inline-block;
    margin-right: 8px;

    .errorIcon {
      color: $red;
    }

    .tooltiptext {
      visibility: hidden;
      width: auto;
      max-width: 300px;
      background: $white;
      color: $red;
      text-align: left;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $alice-blue;
      box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
      position: absolute;
      z-index: 99;
      bottom: 150%;
      transform: translateX(-94%);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  .clearButton {
    color: $dim-gray;
    cursor: pointer;
  }
}
