@use '../../../styles/variables' as *;

.profileView {
    display: flex;
    width: 100%;
    height: 100%;
}

.navigation {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: $eerie-black;
    font-weight: 400;
    line-height: 140%;
    padding: 20px 15px;
    width: 210px;
    height: 100%;
    border-right: 1px solid $anti-flash-white;
    gap: 15px;
}

.menuRow {
    height: 40px;
}

.iconWithLabel {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: 1px;
    gap: 15px;
    font-family: $font-family-base;
  
    &.selected {
        color: $process-cyan;

        .label {
            color: $process-cyan;
        }
    }
  
    &:hover {
        cursor: pointer;
        color: $process-cyan;
        .label {
            color: $process-cyan;
        }
    }
}

.menuIcon {
    height: 20px;
    width: 20px;
}

.label {
    white-space: nowrap;
}

.componentDisplay {
    flex: 1;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.imageFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    vertical-align: middle;
}

.editLabel {
    color: $process-cyan;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:hover {
        background: rgba($process-cyan, 0.12);
    }
}

.image {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.label {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
