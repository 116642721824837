@use '../../../styles/variables' as *;

.filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
    gap: 20px;
    align-items: center;
    height: 140px;
}

.contacts {
    background-color: $seasalt-background;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 5px;
    width: 100%;
}

.list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding: 10px;
    margin: 0;
    max-height: calc(100dvh - 300px);
}




.title {
    color: $black;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    align-self: flex-start;
}

.online {
    color: $black;
    margin-top: 6px;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    align-self: flex-start;
}

.all {
    color: $black;
    margin-top: 12px;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    align-self: flex-start;
}

.list::-webkit-scrollbar {
    width: 12px;
}

.list::-webkit-scrollbar-track {
    background: transparent;
}

.list::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
}

.list::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
}