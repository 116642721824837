@use '../../../../styles/variables' as *;

.selectButton {
    display: flex;
    align-items: center;
    padding: 7px 16.337px 8px 15px;
    border-radius: 50px;
    border: none;
    background-color: $anti-flash-white;
    color: $eerie-black;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-size: 14px;
    font-weight: 500;
    gap: 10.463px;
    position: relative;
    width: 100%;

    &:hover {
        background-color: $blue-light80;
        color: $process-cyan;
    }

    &.active {
        background-color: $blue-light80;
        color: $process-cyan;

        .icon {
            color: $process-cyan;
        }
    }

    &.error {
        border: 1px solid $red;
        color: $red;

        .icon {
            color: $red;
        }

        .plusIcon {
            color: $red;
        }
    }
}

.icon {
    margin-right: 10px;
    font-size: 18px;
    color: $eerie-black;
}

.text {
    flex-grow: 1;
}

.plusIcon {
    font-size: 18px;
    color: $eerie-black;
}

.errorIcon {
    color: $red;
    margin-left: 8px;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        width: auto;
        max-width: 300px;
        background: $white;
        color: $red;
        text-align: left;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $alice-blue;
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
        position: absolute;
        z-index: 99;
        bottom: 150%;
        transform: translateX(-94%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        font-weight: 400;
    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}
