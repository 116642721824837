@use '../../../../../styles/variables' as *;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  display: flex;
  width: 360px;
  height: 480px;
  padding: 40px 0px 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Rich-card, #171A21);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.modalTitle {
  color: $white;
  font-family: $font-family-base;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
button {
  background-color: var(--Rich-card, #171A21);
  border: 0;
}
.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  flex-shrink: 0;
  padding: 51px 0px 0px 0px;
}

.modalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  margin-bottom: 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: $black;

  &:hover {
    color: $process-cyan;
  }

  &:focus {
    outline: none;
  }
}

.avatar {
  display: flex;
  justify-content: center;
  margin: 10px 0px 15px 0px;
}
.contactName {
  display: flex;
  width: 360px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.contactNameText {
  color: var(--White, #FFF);
  text-align: center;
  font-family: $font-family-base;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 42px */
}
.contactRole {
  display: flex;
  width: 360px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.contactRoleText {
  color: var(--Process-Cyan, #00B4E6);
  text-align: center;
  font-family: $font-family-base;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}
.message {
  display: flex;
  height: 65px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
}
.messageText {
  color: var(--Anti-flash-white, #F1F1F1);
  text-align: center;
  font-family: $font-family-base;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}
.acceptButtonClassName {
  color: white;
  display: flex;
  width: 45px;
  height: 45px;
  padding: 7.5px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--Kelly-Green, #00AB00);
}
.rejectButtonClassName {
  color: white;
  display: flex;
  width: 45px;
  height: 45px;
  padding: 7.5px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--Red, #C40024);
}