@use '../../../../styles/variables' as *;

.modalTitle {
    color: $black;
    font-family: $font-family-base;
    font-size: 23px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 15px;
  }
  
  .confirmMessage {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
  }

  .confirmButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
  }
  
  .confirmButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }
  
  
  .cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
  }
  
  .cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
  }