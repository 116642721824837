@use '../../../styles/variables' as *;

.dropdownContainer {
    position: relative;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &.err {
        .dropdownHeader {
            border-color: $red;
            color: $red;
        }
    }

    &.disabled {
        .dropdownHeader {
            background-color: $anti-flash-white;
            border-color: $gray;
            cursor: not-allowed;
            color: $dim-gray;

            &:hover {
                background-color: $anti-flash-white;
                border-color: $gray;
            }
        }

        .dropdownListContainer {
            display: none;
        }

        .listItem {
            cursor: not-allowed;

            &:hover {
                background-color: $white;
            }
        }

        .checkbox {
            cursor: not-allowed;
        }

        .searchInput {
            background-color: $gray;
            cursor: not-allowed;
        }
    }
}

.dropdownHeader {
    background-color: $white;
    border: 0.75px solid $gray;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    transition: border-color 0.3s, color 0.3s;

    &:hover {
        background-color: $blue-light80;
        border-color: $process-cyan;
    }

    &.active {
        border-color: $process-cyan;
        color: $process-cyan;
    }

    > div {
        overflow-wrap: anywhere;
    }
}

.dropdownListContainer {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background-color: $white;
    border: 1px solid $anti-flash-white;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 5px;
    z-index: 25;
    width: 100%;
    padding: 8px 15px 0px 15px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 6px;
        border: 3px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }
}

.dropdownList {
    list-style: none;
    padding-left: 0;

    li {
        margin-top: 8px;
    }
}

.listItem {
    margin-left: 5px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    min-height: 40px;
    background-color: $white;
    border-radius: 5px;

    &:hover {
        background-color: $blue-light80;
    }

    span {
        margin: 0px 15px;
        overflow-wrap: anywhere;
    }
}

.listItemSelected {
    background-color: $blue-light80;
}

.checkbox {
    margin-left: 10px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    accent-color: $process-cyan;
    color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.75px solid $gray;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    &:checked {
        background-color: $process-cyan;
        border-color: $process-cyan;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0px 18px 0px 10px;
    justify-content: space-between;
    border: 0.75px solid $gray;
    border-radius: 5px;
    transition: border-color 0.3s;
    margin-bottom: 8px;

    &.active {
        border-color: $process-cyan;
    }

    &:focus-within {
        border-color: $process-cyan;

        .searchIcon {
            color: $process-cyan;
        }
    }
}

.searchInput {
    width: 100%;
    border: none;
    outline: none;
    background: none;
}

.searchIcon {
    color: $gray;
    pointer-events: none;
    transition: color 0.3s;

    .searchContainer.active & {
        color: $process-cyan;
    }
}

.requiredMark {
    color: $red;
}

.iconGroup {
    display: flex;
}

.errorIcon {
    color: $red;
}

.tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        width: auto;
        max-width: 300px;
        background: $white;
        color: $red;
        text-align: left;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $alice-blue;
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
        position: absolute;
        z-index: 99;
        bottom: 150%;
        transform: translateX(-94%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}

.noOptions {
    color: $dim-gray;
    padding: 4px;
    text-align: center;
    font-size: small;
}