@use '../../../../styles/variables' as *;

li {
    list-style-type: none;
}

.calendarView {
    display: flex;
    width: 100%;
}

.calendarContainer {
    flex-grow: 1;
    margin-left: 20px;
    height: calc(100vh - 200px);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.dateSelector {
    display: flex;
    align-items: center;
}

.navButtonLeft {
    background-color: $seasalt-background;
    border: none;
    border-radius: 0px 5px 5px 0px;
    font-size: 18px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transform: rotate(90deg);
}

.navButtonRight {
    background-color: $seasalt-background;
    border: none;
    border-radius: 0px 5px 5px 0px;
    font-size: 18px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
}

.dateSelector span {
    background-color: $seasalt-background;
    height: 30px;
    width: 330px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.viewButtons {
    display: flex;
    gap: 1px;
    margin-right: 12px;
}

.viewButtons button {
    border: none;
    background-color: $seasalt-background;
    width: 80px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.viewButtons .active {
    background-color: $process-cyan;
    color: #fff;
}

.calendar {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 6px;
        border: 3px solid transparent;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }
}

.custom-event {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    .event-title {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      padding: 2px 4px;
      color: white;
      font-weight: bold;
    }
}

.center {
    text-align: center;
    vertical-align: middle;
}

.modalTitle {
    color: $eerie-black;
    text-align: center;
    font-family: $font-family-base;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.link {
    color: $process-cyan;
    cursor: pointer;
}

.buttonGroup {
    display: flex;
    gap: 25px;
    justify-content: center;
  }