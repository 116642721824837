@use '../../../../styles/variables' as *;

.modalTitle {
    color: $eerie-black;
    text-align: center;
    font-family: $font-family-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.userListContainer {
    max-height: 300px;
    scrollbar-gutter: stable;
    overflow-y: auto;
    border: 0.5px solid $gray;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $anti-flash-white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }
}

.userItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: $blue-light80;
    }
}

.checkbox {
    margin-left: 10px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    accent-color: $process-cyan;
    color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.75px solid $gray;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    &:checked {
        background-color: $process-cyan;
        border-color: $process-cyan;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}

.homeName {
    flex: 1;
    margin-left: 5px;
    font-size: 14px;
    color: $eerie-black;
}

.confirmButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
}

.confirmButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
}

.cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
}

.cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
}

.homeInfo {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.homeTitle {
    color: $dim-gray;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
}

.homeId {
    color: $eerie-black;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0px 18px 0px 10px;
    justify-content: space-between;
    border: 0.75px solid $gray;
    border-radius: 5px;
    transition: border-color 0.3s;
    margin-bottom: 8px;

    &.active {
        border-color: $process-cyan;
    }

    &:focus-within {
        border-color: $process-cyan;

        .searchIcon {
            color: $process-cyan;
        }
    }
}

.searchInput {
    width: 100%;
    border: none;
    outline: none;
    background: none;
}

.searchIcon {
    color: $gray;
    pointer-events: none;
    transition: color 0.3s;

    .searchContainer.active & {
        color: $process-cyan;
    }
}