@use '../../../styles/variables' as *;

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: $font-family-base;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.img {
    margin-bottom: 20px;
}

.title {
    color: $black;
    font: inherit;
    font-size: 100px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.description {
    max-width: 500px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}