@use '../../../../styles/variables' as *;

.container {
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.accordion {
    height: 50px;
    background-color: $b-light;
    transition: height 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.accordion.open {
    height: 40vh;
}

.accordionHeader {
    background-color: $white;
    display: flex;
    padding: 10px;
    user-select: none;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    font-size: 14px;

    .title {
        cursor: pointer;
        flex-grow: 1;
        color: $black;
        font-weight: bold;
        margin-left: 10px;

        .count {
            padding-left: 5px;
            color: $dim-gray;
            font-weight: initial;
        }
    }
}

.historyFilters {
    display: flex;
    gap: 5px;
    margin-right: 50px;

    div {
        text-align: center;
        padding: 5px 10px;
        border-radius: 20px;
        width: 85px;
        cursor: pointer;
        color: $black;

        &:hover {
            background-color: $blue-light80;
        }

        &.active {
            background-color: $blue-light80;
            color: $process-cyan;
        }
    }
}

.dropdownIcon:hover {
    color: $process-cyan;
    cursor: pointer;
}

.accordionContent {
    padding: 10px;
    background-color: $white;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: clamp(12px, 2vw, 16px);
}

.accordionContent::-webkit-scrollbar {
    width: 8px;
}

.accordionContent::-webkit-scrollbar-track {
    background-color: transparent;
}

.accordionContent::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.accordionContent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $b-light;
    padding: 20px;
}

.times {
    width: 400px;
    display: flex;
    gap: 40%;
}

.state {
    display: flex;
    gap: 10px;
    width: 300px;
}

.duration {
    display: flex;
    gap: 10px;
    width: 200px;
}

.red {
    color: $red;
    font-weight: bold;
}

.black {
    color: $black;
    font-weight: bold;
}

.gray {
    color: $gray;
}