@use '../../../styles/variables' as *;

.modalTitle {
  color: $eerie-black;
  text-align: center;
  font-family: $font-family-base;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.confirmButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }
}

.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
  }
}

.notificationBody {
  font-family: $font-family-base;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $eerie-black;
  font-size: 14px;
}

.notificationTime {
  font-family: $font-family-base;
  padding-top: 15px;
  font-size: 10px;
  color: $dim-gray;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 5px;
}

.notificationTime span {
  font-family: $font-family-base;
}

.notificationDetailContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
}

.notificationIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: $process-cyan;
  font-size: 20px;
}
