@use '../../../styles/variables' as *;

*, *::before, *::after {
    box-sizing: border-box;
}


.filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.groups {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.list {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
    scrollbar-gutter: stable;
    width: 100%;


    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 6px;
        border: 3px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dim-gray;
    }

    .listContent {
        margin-left: 10px;
    }
}

.listUser {
    @extend .list;
    max-height: calc(100dvh - 170px);

}

.listAdmin {
    @extend .list;
    max-height: calc(100dvh - 245px);

}


.title {
    color: $black;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.online {
    color: $black;
    margin-top: 6px;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.all {
    color: $black;
    margin-top: 12px;
    line-height: 1.4;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.createGroupButton {
    font-size: 14px;
    color: $white;
    border-radius: 5px;
    background-color: $process-cyan;
    border: none;
    cursor: pointer;
    height: 35px;
    padding: 0 10px;

    &:hover {
        transition: 0.5s;
        background-color: $blue;
    }
}

.sectionTitle {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 10px;
}
