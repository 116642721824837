@use '../../../../styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  display: flex;
  flex-direction: column;
}

.label {
  color: $eerie-black;
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
}

.daysOfWeek,
.weeksOfMonth {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.dayButton,
.weekButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  user-select: none;
}

.dayButton {
  width: 40px;
  height: 40px;
  border: 1px solid $anti-flash-white;
  border-radius: 50%;
  background-color: $anti-flash-white;
  color: $eerie-black;
  font-size: 14px;

  &:hover {
    background-color: $seasalt;
    border-color: $process-cyan;
  }

  &.selected {
    background-color: $process-cyan;
    border-color: $process-cyan;
    color: $white;
  }
}

.weekButton {
  width: 100px;
  height: 40px;
  border: 1px solid $anti-flash-white;
  border-radius: 5px;
  background-color: $anti-flash-white;
  color: $eerie-black;
  font-size: 14px;

  &:hover {
    background-color: $seasalt;
    border-color: $process-cyan;
  }

  &.selected {
    background-color: $process-cyan;
    border-color: $process-cyan;
    color: $white;
  }
}

.error {
  color: $red;
  font-size: 12px;
  margin-top: 5px;
}

.requiredMark {
  color: $red;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.saveButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
  }
  
  .saveButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
  }
  
  .cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    grid-column: 1 / span 2;
    justify-self: start;
  }
  
  .cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
  }

/* Responsive Design */
@media (max-width: 600px) {
  .dayButton,
  .weekButton {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }

  .weekButton {
    width: 80px;
  }
}
