@use '../../../../styles/variables' as *;

.configProfile {
    width: 100%;
    margin: 0 auto;
}

.title {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tableRow {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $anti-flash-white;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }
}

.tableCell {
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;
}

.tableCell:nth-child(2) {
    justify-content: flex-end;
}
.tableCell:nth-child(3) {
    justify-content: flex-end;
}
.label {
    color: $eerie-black;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.value {
    color: $dim-gray;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
}

.inputField {
    width: 100%;
}

.editLabel {
    color: $process-cyan;
    font-family: $font-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:hover {
        background: rgba($process-cyan, 0.12);
    }
}

.buttonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
}

.select {
    width: 220px;
}

.saveButton {
    color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    background: $process-cyan;
    grid-column: 1 / span 2;
    justify-self: end;
}
  
.saveButton:hover {
    transition: 0.5s;
    background-color: $blue;
    border-color: $blue;
}
  
.cancelButton {
    color: $process-cyan;
    background-color: $white;
    display: flex;
    width: 110px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $process-cyan;
    grid-column: 1 / span 2;
    justify-self: start;
}
  
.cancelButton:hover {
    transition: 0.5s;
    color: $white;
    background-color: $process-cyan;
}

.buttonRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.checkbox {
    margin-left: 10px;
    width: 18px;
    height: 18px;
    accent-color: $process-cyan;
    color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.75px solid $gray;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    &:checked {
        background-color: $process-cyan;
        border-color: $process-cyan;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    
}

.disabled {
    color: $dim-gray !important;
    input {
        cursor: not-allowed;
        color: $dim-gray;
    }
}

